//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;
	static STRING_URL_INTEGRATION_API: string = environment.URL_INTEGRATION_API;
	static STRING_URL_CLAIM_API: string = environment.URL_CLAIM_API;

	//#endregion


	//#region URL WEBSITE SOCKET

	/*
	URL_USER_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_USER_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_USER;
	URLT_PRODUCT_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_PRODUCT_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_PRODUCT;
	URL_POLICY_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_POLICY_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_POLICY;
	*/

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORSTRATEGICANDPLANNING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForStrategicAndPlanning`;

	//#endregion


	//#region CONTROLLER DASHBOARD

	static STRING_PATH_CONTROLLER_DASHBOARD: string = "/Dashboard";

	static STRING_URL_DASHBOARD_SELECTDASHBOARDLETTER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardLetter`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORJAMKRINDOSYARIAH: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForJamkrindoSyariah`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORKPRSBRI: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForKPRSBRI`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORASMIKSYARIAHBSI: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForAsmikSyariahBSI`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORACCOUNTINGANDFINANCE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForAccountingAndFinance`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORLEGAL: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForLegal`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORSURVEYOR: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForSurveyor`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORBRIMO: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForBRIMO`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFOROPERATIONALANDSUPPORT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForOperationAndSupport`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORCOMPLIANCEANDRISKMANAGEMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForComplianceAndRiskManagement`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORCOORPORATEPERFORMANCE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForCorporatePerformance`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForBranchHead`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORBRINSMOBILE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForBRINSmobile`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORBRINSWEBSITE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForBRINSwebsite`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORNEWDELIVERYSYSTEM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForNewDeliverySystem`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORSYARIAH: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForSyariah`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORLOGISTIC: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForLogistic`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORPOLICYANDBUSINESSDEVELOPMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForPolicyAndBusinessDevelopment`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORSMART: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForSMART`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORCLAIM: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForClaim`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORBUSINESS: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForBusiness`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDFORUNDERWRITINGANDREASSURANCE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardForUnderwritingAndReassurance`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP COLLECTOR DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT: string = "/DocumentStampCollectorReport";

	static STRING_URL_DOCUMENTSTAMPCOLLECTORREPORT_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT}/selectDocumentStampCollectorReportByAttributes`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTORREPORT_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORREPORTBYATTRIBUTESFOREXCEL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT}/selectDocumentStampCollectorReportByAttributesForExcel`;

	//#endregion


	//#region STRATEGIC AND PLANNING CONTROLLER

	static STRING_PATH_CONTROLLER_STRATEGICANDPLANNING: string = "/StrategicAndPlanning";

	static STRING_URL_STRATEGICANDPLANNING_SELECTSTRATEGICANDPLANNINGBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_STRATEGICANDPLANNING}/selectStrategicAndPlanningByEmail`;
	static STRING_URL_STRATEGICANDPLANNING_VERIFYSTRATEGICANDPLANNINGBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_STRATEGICANDPLANNING}/verifyStrategicAndPlanningByEmail`;
	static STRING_URL_STRATEGICANDPLANNING_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_STRATEGICANDPLANNING}/signOut`;

	//#endregion


	//#region BANK ACCOUNT

	static STRING_PATH_CONTROLLER_BANKACCOUNT: string = "/BankAccount";

	static STRING_URL_BANKACCOUNT_SELECTBANKACCOUNTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/selectBankAccountByAttributes`;
	static STRING_URL_BANKACCOUNT_SELECTBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/selectBankAccountByToken`;
	static STRING_URL_BANKACCOUNT_INSERTBANKACCOUNT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/insertBankAccount`;
	static STRING_URL_BANKACCOUNT_UPDATEBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/updateBankAccountByToken`;
	static STRING_URL_BANKACCOUNT_DELETEBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/deleteBankAccountByToken`;

	//#endregion


	//#region ACCOUNT CHARGE

	static STRING_PATH_CONTROLLER_ACCOUNTCHARGE: string = "/AccountCharge";

	static STRING_URL_ACCOUNTCHARGE_SELECTACCOUNTCHARGEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTCHARGE}/selectAccountChargeByAttributes`;

	//#endregion


	//#region BRANCH CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCH: string = "/Branch";

	static STRING_URL_BRANCH_SELECTBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranch`;
	static STRING_URL_BRANCH_SELECTBRANCHFORTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForTicketByAttributes`;
	static STRING_URL_BRANCH_SELECTBRANCHFORBRINSEDITORIALWORKINGUNIT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForBRINSEditorialWorkingUnit`;

	//#endregion


	//#region DIVISION

	static STRING_PATH_CONTROLLER_DIVISION: string = "/Division";

	static STRING_URL_DIVISION_SELECTDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivision`;
	static STRING_URL_DIVISION_SELECTDIVISIONFORBRINSEDITORIALWORKINGUNIT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivisionForBRINSEditorialWorkingUnit`;

	//#endregion


	//#region TICKET CONTROLLER

	static STRING_PATH_CONTROLLER_TICKET: string = "/Ticket";

	static STRING_URL_TICKET_SELECTTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByToken`;
	static STRING_URL_TICKET_SELECTTICKETINBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketReceivedByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETOUTBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketSentByAttributes`;
	static STRING_URL_TICKET_SELECTREPORTTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectReportTicketByAttributes`;
	static STRING_URL_TICKET_INSERTTICKET: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicket`;
	static STRING_URL_TICKET_INSERTTICKETFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForDivision`;
	static STRING_URL_TICKET_UPDATETICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/updateTicketByToken`;
	static STRING_URL_TICKET_UPDATETICKETBYTOKENFORCLOSEWITHOUTCONFIRMATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/updateTicketByTokenForCloseWithoutConfirmation`;

	//#endregion


	//#region TICKET CONVERSATION CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETCONVERSATION: string = "/TicketConversation";

	static STRING_URL_TICKET_INSERTTICKETCONVERSATIONFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/insertTicketConversationForDivision`;
	static STRING_URL_TICKET_SELECTTICKETCONVERSATIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/selectTicketConversationByAttributes`;
	static STRING_URL_TICKET_UPDATETICKETCONVERSATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/updateTicketConversationByToken`;
	static STRING_URL_TICKET_UPLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/uploadConversation`;

	//#endregion


	//#region CURRENCY

	static STRING_PATH_CONTROLLER_CURRENCY: string = "/Currency";

	static STRING_URL_CURRENCY_SELECTCURRENCY: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CURRENCY}/selectCurrency`;

	//#endregion


	//#region TICKET DOCUMENT CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETDOCUMENT: string = "/TicketDocument";

	static STRING_URL_TICKET_DOWNLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETDOCUMENT}/downloadConversationByDocumentToken`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR TYPE

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORTYPE: string = "/DocumentStampCollectorType";

	static STRING_URL_DOCUMENTSTAMPCOLLECTORTYPE_SELECTDOCUMENTSTAMPCONTROLLERTYPEBYATRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORTYPE}/selectDocumentStampCollectorTypeByAttributes`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR: string = "/DocumentStampCollector";

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_DOWNLOADDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/downloadDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForMaker`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForChecker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForSigner`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SUBMITDOCUMENTSTAMPCONTROLLERFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/submitDocumentStampCollectorForMaker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REVISEDOCUMENTSTAMPCOLLECTORFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/reviseDocumentStampCollectorForMaker`;

	//#region CHECKER

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_APPROVEDOCUMENTSTAMPCOLLECTORBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/approveDocumentStampCollectorByTokenForChecker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REJECTDOCUMENTSTAMPCOLLECTORBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/rejectDocumentStampCollectorByTokenForChecker`;

	//#endregion


	//#region SIGNER

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_APPROVEDOCUMENTSTAMPCOLLECTORBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/approveDocumentStampCollectorByTokenForSigner`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REJECTDOCUMENTSTAMPCOLLECTORBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/rejectDocumentStampCollectorByTokenForSigner`;

	//#endregion

	//#endregion


	//#region FINANCIAL HIGHLIGHT HEADER

	static STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTHEADER: string = "/FinancialHighlightHeader";

	static STRING_URL_FINANCIALHIGHLIGHT_SELECTFINANCIALHIGHLIGHTHEADERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTHEADER}/selectFinancialHighlightHeaderByAttributes`;
	static STRING_URL_FINANCIALHIGHLIGHT_SELECTFINANCIALHIGHLIGHTHEADER_FOR_CORPORATE_PERFORMANCE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTHEADER}/selectFinancialHighlightHeaderForCorporatePerformance`;

	//#endregion


	//#region CAPITAL EXPENDITURE HEADER

	static STRING_PATH_CONTROLLER_CAPITALEXPENDITUREHEADER: string = "/CapitalExpenditureHeader";

	static STRING_URL_CAPITALEXPENDITUREHEADER_SELECTCAPITALEXPENDITUREHEADERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPITALEXPENDITUREHEADER}/selectCapitalExpenditureHeaderByAttributes`;
	static STRING_URL_CAPITALEXPENDITUREHEADER_SELECT_CAPITAL_EXPENDITUREHEADER_BYATTRIBUTES_FOR_CORPORATE_PERFORMANCE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPITALEXPENDITUREHEADER}/selectCapitalExpenditureHeaderForCorporatePerformance`;

	//#endregion

	//#region CLASS OF BUSINESS HEADER

	static STRING_PATH_CONTROLLER_CLASSOFBUSINESSHEADER: string = "/ClassOfBusinessHeader";

	static STRING_URL_CLASSOFBUSINESSHEADER_SELECTCLASSOFBUSINESSHEADERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLASSOFBUSINESSHEADER}/selectClassOfBusinessHeaderByAttributes`;
	static STRING_URL_CLASSOFBUSINESSHEADER_SELECTCLASSOFBUSINESSHEADERBYATTRIBUTES_FOR_CORPORATE_PERFORMANCE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLASSOFBUSINESSHEADER}/selectClassOfBusinessHeaderForCorporatePerformance`;

	//#endregion


	//#region OPERATION EXPENDITURE

	static STRING_PATH_CONTROLLER_OPERATIONEXPENDITUREHEADER: string = "/OperationExpenditureHeader";

	static STRING_URL_OPERATIONEXPENDITUREHEADER_SELECTOPERATIONEXPENDITUREHEADERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPERATIONEXPENDITUREHEADER}/selectOperationExpenditureHeaderByAttributes`;

	//#endregion


	//#region RATIO HIGHLIGHT HEADER

	static STRING_PATH_CONTROLLER_RATIOHIGHLIGHTHEADER: string = "/RatioHighlightHeader";

	static STRING_URL_RATIOHIGHLIGHT_SELECTRATIONHIGHLIGHTHEADERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RATIOHIGHLIGHTHEADER}/selectRatioHighlightHeaderByAttributes`;
	static STRING_URL_RATIOHIGHLIGHT_SELECTRATIONHIGHLIGHTHEADER_FORCORPORATEPERFORMANCE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RATIOHIGHLIGHTHEADER}/selectRatioHighlightHeaderForCorporatePerformance`;

	//#endregion


	//#region FEE BASED INCOME HEADER

	static STRING_PATH_CONTROLLER_FEEBASEDINCOMEHEADER: string = "/FeeBasedIncomeHeader";

	static STRING_URL_RATIOHIGHLIGHT_SELECTFEEBASEDINCOMEHEADERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FEEBASEDINCOMEHEADER}/selectFeeBasedIncomeHeaderByAttributes`;
	static STRING_URL_RATIOHIGHLIGHT_SELECTFEEBASEDINCOMEHEADER_FOR_CORPORATE_PERFORMANCE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FEEBASEDINCOMEHEADER}/selectFeeBasedIncomeHeaderForCorporatePerformance`;

	//#endregion


	//#region INVESTMENT HEADER

	static STRING_PATH_CONTROLLER_INVESTMENTHEADER: string = "/InvestmentHeader";

	static STRING_URL_INVESTMENTHEADER_SELECTINVESTMENTHEADERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INVESTMENTHEADER}/selectInvestmentHeaderByAttributes`;
	static STRING_URL_INVESTMENTHEADER_SELECTINVESTMENTHEADER_FOR_CORPORATE_PERFORMANCE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INVESTMENTHEADER}/selectInvestmentHeaderForCorporatePerformance`;

	//#endregion


	//#region SEGMENT HEADER

	static STRING_PATH_CONTROLLER_SEGMENTHEADER: string = "/SegmentHeader";

	static STRING_URL_SEGMENTHEADER_SELECTSEGMENTHEADERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SEGMENTHEADER}/selectSegmentHeaderByAttributes`;
	static STRING_URL_SEGMENTHEADER_SELECTSEGMENTHEADER_FOR_CORPORATE_PERFORMANCE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SEGMENTHEADER}/selectSegmentHeaderForCorporatePerformance`;

	//#endregion


	//#region FINANCIAL HIGHLIGHT

	static STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHT: string = "/FinancialHighlight";

	static STRING_URL_FINANCIALHIGHLIGHT_SELECTFINANCIALHIGHLIGHT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHT}/selectFinancialHighlight`;

	//#endregion


	//#region INVESTMENT

	static STRING_PATH_CONTROLLER_INVESTMENT: string = "/Investment";

	static STRING_URL_INVESTMENT_SELECTINVESTMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INVESTMENT}/selectInvestment`;

	//#endregion


	//#region CAPITAL EXPENDITURE

	static STRING_PATH_CONTROLLER_CAPITALEXPENDITURE: string = "/CapitalExpenditure";

	static STRING_URL_CAPITALEXPENDITURE_SELECTCAPITALEXPENDITURE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPITALEXPENDITURE}/selectCapitalExpenditure`;

	//#endregion


	//#region OPERATION EXPENDITURE

	static STRING_PATH_CONTROLLER_OPERATIONEXPENDITURE: string = "/OperationExpenditure";

	static STRING_URL_OPERATIONXPENDITURE_SELECTOPERATIONEXPENDITURE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPERATIONEXPENDITURE}/selectOperationExpenditure`;

	//#endregion


	//#region FEE BASED INCOME

	static STRING_PATH_CONTROLLER_FEEBASEDINCOME: string = "/FeeBasedIncome";

	static STRING_URL_FEEBASEDINCOME_SELECTFEEBASEDINCOME: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FEEBASEDINCOME}/selectFeeBasedIncome`;

	//#endregion


	//#region PILLAR PORTFOLIO SEGMENT

	static STRING_PATH_CONTROLLER_PILLARPORTFOLIOSEGMENT: string = "/PillarPortfolioSegment";

	static STRING_URL_PILLARPORTFOLIOSEGMENT_SELECTPILLARPORTFOLIOSEGMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PILLARPORTFOLIOSEGMENT}/selectPillarPortfolioSegmentByAttributes`;

	//#endregion


	//#region CAPTIVE PORTFOLIO SEGMENT

	static STRING_PATH_CONTROLLER_CAPTIVEPORTFOLIOSEGMENT: string = "/CaptivePortfolioSegment";

	static STRING_URL_CAPTIVEPORTFOLIOSEGMENT_SELECTCAPTIVEPORTFOLIOSEGMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPTIVEPORTFOLIOSEGMENT}/selectCaptivePortfolioSegment`;

	//#endregion


	//#region DIGITAL PORTFOLIO SEGMENT

	static STRING_PATH_CONTROLLER_DIGITALPORTFOLIOSEGMENT: string = "/DigitalPortfolioSegment";

	static STRING_URL_DIGITALPORTFOLIOSEGMENT_SELECTDIGITALPORTFOLIOSEGMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIGITALPORTFOLIOSEGMENT}/selectDigitalPortfolioSegment`;

	//#endregion


	//#region SYARIAH PORTFOLIO SEGMENT

	static STRING_PATH_CONTROLLER_SYARIAHPORTFOLIOSEGMENT: string = "/SyariahPortfolioSegment";

	static STRING_URL_SYARIAHPORTFOLIOSEGMENT_SELECTSYARIAHPORTFOLIOSEGMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SYARIAHPORTFOLIOSEGMENT}/selectSyariahPortfolioSegmentByAttributes`;

	//#endregion


	//#region UNCOMMITED CREDIT LINE

	static STRING_PATH_CONTROLLER_UNCOMMITEDCREDITLINE: string = "/UncommitedCreditLine";

	static STRING_URL_UNCOMMITEDCREDITLINE_SELECTUNCOMMITEDCREDITLINE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UNCOMMITEDCREDITLINE}/selectUncommitedCreditLine`;

	//#endregion


	//#region RATIO HIGHLIGHT HEADER

	static STRING_PATH_CONTROLLER_RATIOHIGHLIGHT: string = "/RatioHighlight";

	static STRING_URL_RATIOHIGHLIGHT_SELECTRATIONHIGHLIGHT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RATIOHIGHLIGHT}/selectRatioHighlight`;

	//#endregion


	//#region WORK UNIT HEADER

	static STRING_PATH_CONTROLLER_WORKUNITHEADER: string = "/WorkUnitHeader";

	static STRING_URL_WORKUNITHEADER_SELECTWORKUNITHEADER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_WORKUNITHEADER}/selectWorkUnitHeaderByAttributes`;
	static STRING_URL_WORKUNITHEADER_SELECTWORKUNITHEADER_FOR_CORPORATE_PERFORMANCE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_WORKUNITHEADER}/selectWorkUnitHeaderForCorporatePerformance`;

	//#endregion


	//#region TOTAL SUM INSURED

	static STRING_PATH_CONTROLLER_TOTALSUMINSUREDHEADER: string = "/TotalSumInsuredHeader";

	static STRING_URL_WORKUNITHEADER_SELECTTOTALSUMINSUREDHEADERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TOTALSUMINSUREDHEADER}/selectTotalSumInsuredHeaderByAttributes`;
	static STRING_URL_WORKUNITHEADER_SELECTTOTALSUMINSUREDHEADERBYATTRIBUTES_FOR_CORPORATE_PERFROMANCE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TOTALSUMINSUREDHEADER}/selectTotalSumInsuredHeaderForCorporatePerformance`;

	//#endregion


	//#region REN MANUAL TOTAL ASET

	static STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTTOTALASSET: string = "/FinancialHighlightTotalAsset";

	static STRING_URL_RATIOHIGHLIGHT_SELECTFINANCIALHIGHLIGHTTOTALASSETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTTOTALASSET}/selectFinancialHighlightTotalAssetByAttributes`;
	static STRING_URL_RATIOHIGHLIGHT_SELECTFINANCIALHIGHLIGHTTOTALASSETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTTOTALASSET}/selectFinancialHighlightTotalAssetByToken`;
	static STRING_URL_RATIOHIGHLIGHT_INSERTFINANCIALHIGHLIGHTTOTALASSET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTTOTALASSET}/insertFinancialHighlightTotalAsset`;
	static STRING_URL_RATIOHIGHLIGHT_UPDATEFINANCIALHIGHLIGHTTOTALASSETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTTOTALASSET}/updateFinancialHighlightTotalAssetByToken`;
	static STRING_URL_RATIOHIGHLIGHT_DEACTIVEFINANCIALHIGHLIGHTTOTALASSETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTTOTALASSET}/deactivateFinancialHighlightTotalAssetByToken`;

	//#endregion


	//#region REN MANUAL PLAFOND

	static STRING_PATH_CONTROLLER_UNCOMMITEDCREDITLINEPLAFOND: string = "/UncommitedCreditLinePlafond";

	static STRING_URL_UNCOMMITEDCREDITLINEPLAFOND_SELECTUNCOMMITEDCREDITLINEPLAFONDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UNCOMMITEDCREDITLINEPLAFOND}/selectUncommitedCreditLinePlafondByAttributes`;
	static STRING_URL_UNCOMMITEDCREDITLINEPLAFOND_SELECTUNCOMMITEDCREDITLINEPLAFONDBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UNCOMMITEDCREDITLINEPLAFOND}/selectUncommitedCreditLinePlafondByToken`;
	static STRING_URL_UNCOMMITEDCREDITLINEPLAFOND_INSERTUNCOMMITEDCREDITLINEPLAFOND: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UNCOMMITEDCREDITLINEPLAFOND}/insertUncommitedCreditLinePlafond`;
	static STRING_URL_UNCOMMITEDCREDITLINEPLAFOND_UPDATEUNCOMMITEDCREDITLINEPLAFONDBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UNCOMMITEDCREDITLINEPLAFOND}/updateUncommitedCreditLinePlafondByToken`;
	static STRING_URL_UNCOMMITEDCREDITLINEPLAFOND_DEACTIVEUNCOMMITEDCREDITLINEPLAFONDBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UNCOMMITEDCREDITLINEPLAFOND}/deactivateUncommitedCreditLinePlafondByToken`;

	//#endregion


	//#region REN MANUAL CAPITAL EXPENDITURE

	static STRING_PATH_CONTROLLER_CAPITALEXPENDITURETRANSACTION: string = "/CapitalExpenditureTransaction";

	static STRING_URL_CAPITALEXPENDITURETRANSACTION_SELECTCAPITALEXPENDITURETRANSACTIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPITALEXPENDITURETRANSACTION}/selectCapitalExpenditureTransactionByAttributes`;
	static STRING_URL_CAPITALEXPENDITURETRANSACTION_SELECTCAPITALEXPENDITURETRANSACTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPITALEXPENDITURETRANSACTION}/selectCapitalExpenditureTransactionByToken`;
	static STRING_URL_CAPITALEXPENDITURETRANSACTION_INSERTCAPITALEXPENDITURETRANSACTION: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPITALEXPENDITURETRANSACTION}/insertCapitalExpenditureTransaction`;
	static STRING_URL_CAPITALEXPENDITURETRANSACTION_UPDATECAPITALEXPENDITURETRANSACTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPITALEXPENDITURETRANSACTION}/updateCapitalExpenditureTransactionByToken`;
	static STRING_URL_CAPITALEXPENDITURETRANSACTION_DEACTIVECAPITALEXPENDITURETRANSACTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPITALEXPENDITURETRANSACTION}/deactivateCapitalExpenditureTransactionByToken`;

	//#endregion

	//#region REN MANUAL RISK BASED CAPITAL

	static STRING_PATH_CONTROLLER_RATIOHIGHLIGHTRISKBASEDCAPITAL: string = "/RatioHighlightRiskBasedCapital";

	static STRING_URL_RATIOHIGHLIGHTRISKBASEDCAPITAL_SELECTRATIOHIGHLIGHTRISKBASEDCAPITALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RATIOHIGHLIGHTRISKBASEDCAPITAL}/selectRatioHighlightRiskBasedCapitalByAttributes`;
	static STRING_URL_RATIOHIGHLIGHTRISKBASEDCAPITAL_SELECTRATIOHIGHLIGHTRISKBASEDCAPITALBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RATIOHIGHLIGHTRISKBASEDCAPITAL}/selectRatioHighlightRiskBasedCapitalByToken`;
	static STRING_URL_RATIOHIGHLIGHTRISKBASEDCAPITAL_INSERTRATIOHIGHLIGHTRISKBASEDCAPITAL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RATIOHIGHLIGHTRISKBASEDCAPITAL}/insertRatioHighlightRiskBasedCapital`;
	static STRING_URL_RATIOHIGHLIGHTRISKBASEDCAPITAL_UPDATERATIOHIGHLIGHTRISKBASEDCAPITALBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RATIOHIGHLIGHTRISKBASEDCAPITAL}/updateRatioHighlightRiskBasedCapitalByToken`;
	static STRING_URL_RATIOHIGHLIGHTRISKBASEDCAPITAL_DEACTIVERATIOHIGHLIGHTRISKBASEDCAPITALBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RATIOHIGHLIGHTRISKBASEDCAPITAL}/deactivateRatioHighlightRiskBasedCapitalByToken`;

	//#endregion


	//#region REN MANUAL INDUSTRY

	static STRING_PATH_CONTROLLER_RENMANUALRATIOHIGHLIGHTINDUSTRY: string = "/RatioHighlightIndustry";

	static STRING_URL_RENMANUALRATIOHIGHLIGHTINDUSTRY_SELECTRRATIOHIGHLIGHTINDUSTRYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RENMANUALRATIOHIGHLIGHTINDUSTRY}/selectRatioHighlightIndustryByAttributes`;
	static STRING_URL_RENMANUALRATIOHIGHLIGHTINDUSTRY_SELECTRATIONHIGHLIGHTINDUSTRYBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RENMANUALRATIOHIGHLIGHTINDUSTRY}/selectRatioHighlightIndustryByToken`;
	static STRING_URL_RENMANUALRATIOHIGHLIGHTINDUSTRY_INSERTRATIOHIGHLIGHT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RENMANUALRATIOHIGHLIGHTINDUSTRY}/insertRatioHighlightIndustry`;
	static STRING_URL_RENMANUALRATIOHIGHLIGHTINDUSTRY_UPDATERATIOHIGHLIGHTBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RENMANUALRATIOHIGHLIGHTINDUSTRY}/updateRatioHighlightIndustryByToken`;
	static STRING_URL_RENMANUALRATIOHIGHLIGHTINDUSTRY_DEACTIVERATIOHIGHLIGHTINDUSTRYBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RENMANUALRATIOHIGHLIGHTINDUSTRY}/deactivateRatioHighlightIndustryByToken`;

	//#endregion


	//#region OUTSTANDING PREMIUM HEADER

	static STRING_PATH_CONTROLLER_OUTSTANDINGPREMIUMHEADER: string = "/OutstandingPremiumHeader";

	static STRING_URL_OUTSTANDINGPREMIUMHEADER_SELECTOUTSTANDINGPREMIUMHEADERYEARLYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OUTSTANDINGPREMIUMHEADER}/selectOutstandingPremiumHeaderYearlyByAttributes`;
	static STRING_URL_OUTSTANDINGPREMIUMHEADER_SELECTOUTSTANDINGPREMIUMHEADERMONTHLYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OUTSTANDINGPREMIUMHEADER}/selectOutstandingPremiumHeaderMonthlyByAttributes`;
	static STRING_URL_OUTSTANDINGPREMIUMHEADER_SELECTOUTSTANDINGPREMIUMHEADER_FOR_CORPORATE_PERFORMANCE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OUTSTANDINGPREMIUMHEADER}/selectOutstandingPremiumHeaderMonthlyForCorporatePerformance`;
	static STRING_URL_OUTSTANDINGPREMIUMHEADER_SELECTOUTSTANDINGPREMIUMHEADER_YEARLY_FORPERFORMANCE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OUTSTANDINGPREMIUMHEADER}/selectOutstandingPremiumHeaderYearlyForCorporatePerformance`;

	//#endregion


	//#region SUPERVISION BRANCH WORKPLAN BUDGET

	static STRING_PATH_CONTROLLER_SUPERVISIONBRANCHWORKPLANBUDGET: string = "/SupervisionBranchWorkPlanBudget";

	static STRING_URL_SUPERVISIONBRANCHWORKPLANBUDGET_SELECTSUPERVISIONBRANCHWORKPLANBUDGETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISIONBRANCHWORKPLANBUDGET}/selectSupervisionBranchWorkPlanBudgetByAttributes`;
	static STRING_URL_SUPERVISIONBRANCHWORKPLANBUDGET_SELECTSUPERVISIONBRANCHWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISIONBRANCHWORKPLANBUDGET}/selectSupervisionBranchWorkPlanBudgetByToken`;
	static STRING_URL_SUPERVISIONBRANCHWORKPLANBUDGET_INSERTSUPERVISIONBRANCHWORKPLANBUDGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISIONBRANCHWORKPLANBUDGET}/insertSupervisionBranchWorkPlanBudget`;
	static STRING_URL_SUPERVISIONBRANCHWORKPLANBUDGET_UPDATESUPERVISIONBRANCHWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISIONBRANCHWORKPLANBUDGET}/updateSupervisionBranchWorkPlanBudgetByToken`;
	static STRING_URL_SUPERVISIONBRANCHWORKPLANBUDGET_DEACTIVATESUPERVISIONBRANCHWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISIONBRANCHWORKPLANBUDGET}/deactivateSupervisionBranchWorkPlanBudgetByToken`;

	//#endregion


	//#region PERFORMANCE SEGMENT WORKPLAN BUDGET

	static STRING_PATH_CONTROLLER_PERFORMANCESEGMENTWORKPLANBUDGET: string = "/PerformanceSegmentWorkPlanBudget";

	static STRING_URL_PERFORMANCESEGMENTWORKPLANBUDGET_SELECTPERFORMANCESEGMENTWORKPLANBUDGETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PERFORMANCESEGMENTWORKPLANBUDGET}/selectPerformanceSegmentWorkPlanBudgetByAttributes`;
	static STRING_URL_PERFORMANCESEGMENTWORKPLANBUDGET_SELECTPERFORMANCESEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PERFORMANCESEGMENTWORKPLANBUDGET}/selectPerformanceSegmentWorkPlanBudgetByToken`;
	static STRING_URL_PERFORMANCESEGMENTWORKPLANBUDGET_INSERTPERFORMANCESEGMENTWORKPLANBUDGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PERFORMANCESEGMENTWORKPLANBUDGET}/insertPerformanceSegmentWorkPlanBudget`;
	static STRING_URL_PERFORMANCESEGMENTWORKPLANBUDGET_UPDATEPERFORMANCESEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PERFORMANCESEGMENTWORKPLANBUDGET}/updatePerformanceSegmentWorkPlanBudgetByToken`;
	static STRING_URL_PERFORMANCESEGMENTWORKPLANBUDGET_DEACTIVATEPERFORMANCESEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PERFORMANCESEGMENTWORKPLANBUDGET}/deactivatePerformanceSegmentWorkPlanBudgetByToken`;

	//#endregion


	//#region FEE BASED INCOME WORKPLAN BUDGET

	static STRING_PATH_CONTROLLER_FEEBASEDINCOMEWORKPLANBUDGET: string = "/FeeBasedIncomeWorkPlanBudget";

	static STRING_URL_FEEBASEDINCOMEWORKPLANBUDGET_SELECTFEEBASEDINCOMEWORKPLANBUDGETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FEEBASEDINCOMEWORKPLANBUDGET}/selectFeeBasedIncomeWorkPlanBudgetByAttributes`;
	static STRING_URL_FEEBASEDINCOMEWORKPLANBUDGET_SELECTFEEBASEDINCOMEWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FEEBASEDINCOMEWORKPLANBUDGET}/selectFeeBasedIncomeWorkPlanBudgetByToken`;
	static STRING_URL_FEEBASEDINCOMEWORKPLANBUDGET_INSERTFEEBASEDINCOMEWORKPLANBUDGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FEEBASEDINCOMEWORKPLANBUDGET}/insertFeeBasedIncomeWorkPlanBudget`;
	static STRING_URL_FEEBASEDINCOMEWORKPLANBUDGET_UPDATEFEEBASEDINCOMEWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FEEBASEDINCOMEWORKPLANBUDGET}/updateFeeBasedIncomeWorkPlanBudgetByToken`;
	static STRING_URL_FEEBASEDINCOMEWORKPLANBUDGET_DEACTIVATEFEEBASEDINCOMEWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FEEBASEDINCOMEWORKPLANBUDGET}/deactivateFeeBasedIncomeWorkPlanBudgetByToken`;

	//#endregion


	//#region INVESTMENT WORK PLAN BUDGET

	static STRING_PATH_CONTROLLER_INVESTMENTWORKPLANBUDGET: string = "/InvestmentWorkPlanBudget";

	static STRING_URL_INVESTMENTWORKPLANBUDGET_SELECTINVESTMENTWORKPLANBUDGETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INVESTMENTWORKPLANBUDGET}/selectInvestmentWorkPlanBudgetByAttributes`;
	static STRING_URL_INVESTMENTWORKPLANBUDGET_SELECTINVESTMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INVESTMENTWORKPLANBUDGET}/selectInvestmentWorkPlanBudgetByToken`;
	static STRING_URL_INVESTMENTWORKPLANBUDGET_INSERTINVESTMENTWORKPLANBUDGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INVESTMENTWORKPLANBUDGET}/insertInvestmentWorkPlanBudget`;
	static STRING_URL_INVESTMENTWORKPLANBUDGET_UPDATEINVESTMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INVESTMENTWORKPLANBUDGET}/updateInvestmentWorkPlanBudgetByToken`;
	static STRING_URL_INVESTMENTWORKPLANBUDGET_DEACTIVATEINVESTMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INVESTMENTWORKPLANBUDGET}/deactivateInvestmentWorkPlanBudgetByToken`;

	//#endregion


	//#region PILLAR PORTFOLIO SEGMENT WORKPLAN BUDGET

	static STRING_PATH_CONTROLLER_PILLARPORTFOLIOSEGMENTWORKPLANBUDGET: string = "/PillarPortfolioSegmentWorkPlanBudget";

	static STRING_URL_PILLARPORTFOLIOSEGMENTWORKPLANBUDGET_SELECTPILLARPORTFOLIOSEGMENTWORKPLANBUDGETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PILLARPORTFOLIOSEGMENTWORKPLANBUDGET}/selectPillarPortfolioSegmentWorkPlanBudgetByAttributes`;
	static STRING_URL_PILLARPORTFOLIOSEGMENTWORKPLANBUDGET_SELECTPILLARPORTFOLIOSEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PILLARPORTFOLIOSEGMENTWORKPLANBUDGET}/selectPillarPortfolioSegmentWorkPlanBudgetByToken`;
	static STRING_URL_PILLARPORTFOLIOSEGMENTWORKPLANBUDGET_INSERTPILLARPORTFOLIOSEGMENTWORKPLANBUDGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PILLARPORTFOLIOSEGMENTWORKPLANBUDGET}/insertPillarPortfolioSegmentWorkPlanBudget`;
	static STRING_URL_PILLARPORTFOLIOSEGMENTWORKPLANBUDGET_UPDATEPILLARPORTFOLIOSEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PILLARPORTFOLIOSEGMENTWORKPLANBUDGET}/updatePillarPortfolioSegmentWorkPlanBudgetByToken`;
	static STRING_URL_PILLARPORTFOLIOSEGMENTWORKPLANBUDGET_DEACTIVATEPILLARPORTFOLIOSEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PILLARPORTFOLIOSEGMENTWORKPLANBUDGET}/deactivatePillarPortfolioSegmentWorkPlanBudgetByToken`;

	//#endregion


	//#region CAPTIVE PORTFOLIO SEGMENT WORKPLAN BUDGET

	static STRING_PATH_CONTROLLER_CAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGET: string = "/CaptivePortfolioSegmentWorkPlanBudget";

	static STRING_URL_CAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGET_SELECTCAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGET}/selectCaptivePortfolioSegmentWorkPlanBudgetByAttributes`;
	static STRING_URL_CAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGET_SELECTCAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGET}/selectCaptivePortfolioSegmentWorkPlanBudgetByToken`;
	static STRING_URL_CAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGET_INSERTCAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGET}/insertCaptivePortfolioSegmentWorkPlanBudget`;
	static STRING_URL_CAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGET_UPDATECAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGET}/updateCaptivePortfolioSegmentWorkPlanBudgetByToken`;
	static STRING_URL_CAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGET_DEACTIVATECAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPTIVEPORTFOLIOSEGMENTWORKPLANBUDGET}/deactivateCaptivePortfolioSegmentWorkPlanBudgetByToken`;

	//#endregion


	//#region DIGITAL PORTFOLIO SEGMENT WORKPLAN BUDGET

	static STRING_PATH_CONTROLLER_DIGITALPORTFOLIOSEGMENTWORKPLANBUDGET: string = "/DigitalPortfolioSegmentWorkPlanBudget";

	static STRING_URL_DIGITALPORTFOLIOSEGMENTWORKPLANBUDGET_SELECTDIGITALPORTFOLIOSEGMENTWORKPLANBUDGETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIGITALPORTFOLIOSEGMENTWORKPLANBUDGET}/selectDigitalPortfolioSegmentWorkPlanBudgetByAttributes`;
	static STRING_URL_DIGITALPORTFOLIOSEGMENTWORKPLANBUDGET_SELECTDIGITALPORTFOLIOSEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIGITALPORTFOLIOSEGMENTWORKPLANBUDGET}/selectDigitalPortfolioSegmentWorkPlanBudgetByToken`;
	static STRING_URL_DIGITALPORTFOLIOSEGMENTWORKPLANBUDGET_INSERTDIGITALPORTFOLIOSEGMENTWORKPLANBUDGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIGITALPORTFOLIOSEGMENTWORKPLANBUDGET}/insertDigitalPortfolioSegmentWorkPlanBudget`;
	static STRING_URL_DIGITALPORTFOLIOSEGMENTWORKPLANBUDGET_UPDATEDIGITALPORTFOLIOSEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIGITALPORTFOLIOSEGMENTWORKPLANBUDGET}/updateDigitalPortfolioSegmentWorkPlanBudgetByToken`;
	static STRING_URL_DIGITALPORTFOLIOSEGMENTWORKPLANBUDGET_DEACTIVATEDIGITALPORTFOLIOSEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIGITALPORTFOLIOSEGMENTWORKPLANBUDGET}/deactivateDigitalPortfolioSegmentWorkPlanBudgetByToken`;

	//#endregion


	//#region SYARIAH PORTFOLIO SEGMENT WORKPLAN BUDGET

	static STRING_PATH_CONTROLLER_SYARIAHPORTFOLIOSEGMENTWORKPLANBUDGET: string = "/SyariahPortfolioSegmentWorkPlanBudget";

	static STRING_URL_SYARIAHPORTFOLIOSEGMENTWORKPLANBUDGET_SELECTSYARIAHPORTFOLIOSEGMENTWORKPLANBUDGETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SYARIAHPORTFOLIOSEGMENTWORKPLANBUDGET}/selectSyariahPortfolioSegmentWorkPlanBudgetByAttributes`;
	static STRING_URL_SYARIAHPORTFOLIOSEGMENTWORKPLANBUDGET_SELECTSYARIAHPORTFOLIOSEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SYARIAHPORTFOLIOSEGMENTWORKPLANBUDGET}/selectSyariahPortfolioSegmentWorkPlanBudgetByToken`;
	static STRING_URL_SYARIAHPORTFOLIOSEGMENTWORKPLANBUDGET_INSERTSYARIAHPORTFOLIOSEGMENTWORKPLANBUDGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SYARIAHPORTFOLIOSEGMENTWORKPLANBUDGET}/insertSyariahPortfolioSegmentWorkPlanBudget`;
	static STRING_URL_SYARIAHPORTFOLIOSEGMENTWORKPLANBUDGET_UPDATESYARIAHPORTFOLIOSEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SYARIAHPORTFOLIOSEGMENTWORKPLANBUDGET}/updateSyariahPortfolioSegmentWorkPlanBudgetByToken`;
	static STRING_URL_SYARIAHPORTFOLIOSEGMENTWORKPLANBUDGET_DEACTIVATESYARIAHPORTFOLIOSEGMENTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SYARIAHPORTFOLIOSEGMENTWORKPLANBUDGET}/deactivateSyariahPortfolioSegmentWorkPlanBudgetByToken`;

	//#endregion


	//#region SUPERVISION BRANCH

	static STRING_PATH_CONTROLLER_SUPERVISIONBRANCH: string = "/SupervisionBranch";

	static STRING_URL_SUPERVISIONBRANCH_SELECTSUPERVISIONBRANCH: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISIONBRANCH}/selectSupervisionBranch`;

	//#endregion


	//#region PERFORMANCE SEGMENT

	static STRING_PATH_CONTROLLER_PERFORMANCESEGMENT: string = "/PerformanceSegment";

	static STRING_URL_PERFORMANCESEGMENT_SELECTPERFORMANCESEGMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PERFORMANCESEGMENT}/selectPerformanceSegmentByAttributes`;

	//#endregion

	//#region PERFORMANCE SEGMENT

	static STRING_PATH_CONTROLLER_SOURCEOFBUSINESS: string = "/SourceOfBusiness";

	static STRING_URL_SOURCEOFBUSINESS_SELECTSOURCEOFBUSINESSDISTINCTNAME: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SOURCEOFBUSINESS}/selectSourceOfBusinessDistinctName`;

	//#endregion


	//#region SEGMENT MATRIX HEADER

	static STRING_PATH_CONTROLLER_SEGMENTATIONMATRIXHEADER: string = "/SegmentationMatrixHeader";

	static STRING_URL_SEGMENTATIONMATRIXHEADER_SELECTSEGMENTMATRIXHEADERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SEGMENTATIONMATRIXHEADER}/selectSegmentationMatrixHeaderByAttributes`;
	static STRING_URL_SEGMENTATIONMATRIXHEADER_SELECTSEGMENTMATRIXHEADER_FOR_CORPORATE_PERFORMANCE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SEGMENTATIONMATRIXHEADER}/selectSegmentationMatrixHeaderForCorporatePerformance`;

	//#endregion


	//#region WORK PLAN BUDGET FINANCIAL HIGHLIGHTS

	static STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTWORKPLANBUDGET: string = "/FinancialHighlightWorkPlanBudget";

	static STRING_URL_FINANCIALHIGHLIGHTWORKPLANBUDGET_SELECTFINANCIALHIGHLIGHTWORKPLANBUDGETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTWORKPLANBUDGET}/selectFinancialHighlightWorkPlanBudgetByAttributes`;
	static STRING_URL_FINANCIALHIGHLIGHTWORKPLANBUDGET_SELECTFINANCIALHIGHLIGHTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTWORKPLANBUDGET}/selectFinancialHighlightWorkPlanBudgetByToken`;
	static STRING_URL_FINANCIALHIGHLIGHTWORKPLANBUDGET_INSERTFINANCIALHIGHLIGHTWORKPLANBUDGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTWORKPLANBUDGET}/insertFinancialHighlightWorkPlanBudget`;
	static STRING_URL_FINANCIALHIGHLIGHTWORKPLANBUDGET_UPDATEFINANCIALHIGHLIGHTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTWORKPLANBUDGET}/updateFinancialHighlightWorkPlanBudgetByToken`;
	static STRING_URL_FINANCIALHIGHLIGHTWORKPLANBUDGET_DEACTIVATEFINANCIALHIGHLIGHTWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALHIGHLIGHTWORKPLANBUDGET}/deactivateFinancialHighlightWorkPlanBudgetByToken`;

	//#endregion


	//#region WORK PLAN BUDGET CAPITAL EXPENDITURE

	static STRING_PATH_CONTROLLER_CAPITALEXPENDITUREWORKPLANBUDGET: string = "/CapitalExpenditureWorkPlanBudget";

	static STRING_URL_CAPITALEXPENDITUREWORKPLANBUDGET_SELECTCAPITALEXPENDITUREWORKPLANBUDGETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPITALEXPENDITUREWORKPLANBUDGET}/selectCapitalExpenditureWorkPlanBudgetByAttributes`;
	static STRING_URL_CAPITALEXPENDITUREWORKPLANBUDGET_SELECTCAPITALEXPENDITUREWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPITALEXPENDITUREWORKPLANBUDGET}/selectCapitalExpenditureWorkPlanBudgetByToken`;
	static STRING_URL_CAPITALEXPENDITUREWORKPLANBUDGET_INSERTCAPITALEXPENDITUREWORKPLANBUDGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPITALEXPENDITUREWORKPLANBUDGET}/insertCapitalExpenditureWorkPlanBudget`;
	static STRING_URL_CAPITALEXPENDITUREWORKPLANBUDGET_UPDATECAPITALEXPENDITUREWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPITALEXPENDITUREWORKPLANBUDGET}/updateCapitalExpenditureWorkPlanBudgetByToken`;
	static STRING_URL_CAPITALEXPENDITUREWORKPLANBUDGET_DEACTIVATECAPITALEXPENDITUREWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAPITALEXPENDITUREWORKPLANBUDGET}/deactivateCapitalExpenditureWorkPlanBudgetByToken`;

	//#endregion


	//#region WORK PLAN BUDGET OPERATIONAL EXPENDITURE

	static STRING_PATH_CONTROLLER_OPERATIONALEXPENDITUREWORKPLANBUDGET: string = "/OperationExpenditureWorkPlanBudget";

	static STRING_URL_OPERATIONALEXPENDITUREWORKPLANBUDGET_SELECTOPERATIONALEXPENDITUREWORKPLANBUDGETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPERATIONALEXPENDITUREWORKPLANBUDGET}/selectOperationExpenditureWorkPlanBudgetByAttributes`;
	static STRING_URL_OPERATIONALEXPENDITUREWORKPLANBUDGET_SELECTOPERATIONALEXPENDITUREWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPERATIONALEXPENDITUREWORKPLANBUDGET}/selectOperationExpenditureWorkPlanBudgetByToken`;
	static STRING_URL_OPERATIONALEXPENDITUREWORKPLANBUDGET_INSERTOPERATIONALEXPENDITUREWORKPLANBUDGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPERATIONALEXPENDITUREWORKPLANBUDGET}/insertOperationExpenditureWorkPlanBudget`;
	static STRING_URL_OPERATIONALEXPENDITUREWORKPLANBUDGET_UPDATEOPERATIONALEXPENDITUREWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPERATIONALEXPENDITUREWORKPLANBUDGET}/updateOperationExpenditureWorkPlanBudgetByToken`;
	static STRING_URL_OPERATIONALEXPENDITUREWORKPLANBUDGET_DEACTIVATEOPRATIONALEXPENDITUREWORKPLANBUDGETBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPERATIONALEXPENDITUREWORKPLANBUDGET}/deactivateOperationExpenditureWorkPlanBudgetByToken`;

	//#endregion


	//#region REIMBURSEMENT GENERAL

	static STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL: string = "/reimbursementgeneral";

	static STRING_URL_REIMBURSEMENTGENERAL_SELECTREIMBURSEMENTGENERALBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL}/selectReimbursementGeneralByAttributesForChecker`;
	static STRING_URL_REIMBURSEMENTGENERAL_SELECTREIMBURSEMENTGENERALBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL}/selectReimbursementGeneralByAttributesForSigner`;

	//#endregion


	//#region DENTAL REIMBURSEMENT

	static STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT: string = "/dentalreimbursement";

	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForMaker`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForChecker`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForSigner`;
	static STRING_URL_DENTALREIMBURSEMENT_CHECKDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/checkDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_SIGNDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/signDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_REJECTDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/rejectDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_REVISEDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/reviseDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_UPDATEDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/updateDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_CHECKDENTALREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/checkDentalReimbursementPlafondByUserID`;
	static STRING_URL_DENTALREIMBURSEMENT_DOWNLOADDENTALREIMBURSEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/downloadLogisticDeclarationByDocumentToken?stringHandshakeToken=[HandshakeToken]&stringDeclarationDocumentToken=[DocumentToken]`;
	static STRING_URL_DENTALREIMBURSEMENT_DOWNLOADDENTALREIMBURSEMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/downloadDeclarationDocumentByTokenForLogistic?stringHandshakeToken=[HandshakeToken]&stringLetterGeneralToken=[DocumentToken]`;

	//#endregion


	//#region OPTIC REIMBURSEMENT

	static STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT: string = "/OpticReimbursement";

	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForMaker`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForChecker`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForSigner`;
	static STRING_URL_OPTICREIMBURSEMENT_CHECKOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/checkOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_SIGNOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/signOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_REJECTOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/rejectOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_REVISEOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/reviseOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_UPDATEOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/updateOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_CHECKOPTICREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/checkOpticReimbursementPlafondByUserID`;
	static STRING_URL_OPTICREIMBURSEMENT_DOWNLOADOPTICREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/downloadOpticReimbursementGalleryByToken`;

	//#endregion


	//#region PREMATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT: string = "/PreMaternityReimbursement";

	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_CHECKPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/checkPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SIGNPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/signPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_REJECTPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/rejectPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_REVISEPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/revisePreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_UPDATEPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/updatePreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_CHECKPREMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/checkPreMaternityReimbursementPlafondByUserID`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_DOWNLOADPREMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/downloadPreMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region POSTMATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT: string = "/PostMaternityReimbursement";

	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_CHECKPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/checkPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SIGNPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/signPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_REJECTPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/rejectPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_REVISEPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/revisePostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_UPDATEPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/updatePostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_CHECKPOSTMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/checkPostMaternityReimbursementPlafondByUserID`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_DOWNLOADPOSTMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/downloadPostMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region MATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT: string = "/MaternityReimbursement";

	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_MATERNITYREIMBURSEMENT_CHECKMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/checkMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SIGNMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/signMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_REJECTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/rejectMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_REVISEMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/reviseMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_UPDATEMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/updateMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_CHECKMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/checkMaternityReimbursementPlafondByUserID`;
	static STRING_URL_MATERNITYREIMBURSEMENT_DOWNLOADMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/downloadMaternityReimbursementGalleryByToken`;

	//#endregion

	//#region RISK LIBRARY ACTIVITY

	static STRING_PATH_CONTROLLER_RISKACTIVITY: string = "/RiskActivity";

	static STRING_URL_RISKACTIVITY_SELECTRISKACTIVITYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKACTIVITY}/selectRiskActivityByAttributes`;
	static STRING_URL_RISKACTIVITY_SELECTRISKACTIVITYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKACTIVITY}/selectRiskActivityByToken`;

	//#endregion


	//#region RISK ASSIGN

	static STRING_PATH_CONTROLLER_RISKASSIGN: string = "/RiskAssign";

	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByToken`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForAssessment`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForApproval`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNCONTENTBYRISKASSIGNTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignContentByRiskAssignToken`;
	static STRING_URL_RISKASSIGN_SAVEALLRISKASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/saveAllRiskAssessment`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYTOKENFORAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByTokenForApproval`;
	static STRING_URL_RISKASSIGN_APPROVERISKASSIGNBYTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/approveRiskAssignByTokenForParticipant`;
	static STRING_URL_RISKASSIGN_REJECTRISKASSIGNBYTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/rejectRiskAssignByTokenForParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNQUARTERLYREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignQuarterlyReportByAttributes`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNQUARTERLYREPORTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignQuarterlyReportByToken`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNCONSOLIDATIONREPORTBYPERIOD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignForConsolidationReportByPeriod`;

	//#endregion


	//#region RISK PLAN

	static STRING_PATH_CONTROLLER_RISKPLAN: string = "/RiskPlan";

	static STRING_URL_RISKPLAN_SELECTRISKPLANBYRISKASSIGNCONTENTTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKPLAN}/selectRiskPlanByRiskAssignContentToken`;
	static STRING_URL_RISKPLAN_INSERTRISKPLANBYRISKPLANLIST: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKPLAN}/insertRiskPlanByRiskPlanList`;

	//#endregion


	//#region RISK ASSIGN CONTENT

	static STRING_PATH_CONTROLLER_RISKASSIGNCONTENT: string = "/RiskAssignContent";

	static STRING_URL_RISKASSIGNCONTENT_SELECTRISKASSIGNCONTENTBYRISKASSIGNTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/selectRiskAssignContentByRiskAssignToken`;
	static STRING_URL_RISKASSIGNCONTENT_SELECTRISKASSIGNCONTENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/selectRiskAssignContentByToken`;
	static STRING_URL_RISKASSIGNCONTENT_CALCULATERISKASSIGNCONTENTSCORE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/calculateRiskAssignContentScore`;
	static STRING_URL_RISKASSIGNCONTENT_SAVERISKASSIGNCONTENTFORASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/saveRiskAssignContentForAssessment`;

	//#endregion


	//#region RISK ASSESSMENT GUIDE DOCUMENT

	static STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT: string = "/RiskAssessmentGuideDocument";

	static STRING_URL_RISKASSESSMENTGUIDEDOCUMENT_SELECTRISKASSESSMENTGUIDEDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT}/selectRiskAssessmentGuideDocumentByAttributes`;
	static STRING_URL_RISKASSESSMENTGUIDEDOCUMENT_DOWNLOADRISKASSESSMENTGUIDEDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT}/downloadRiskAssessmentGuideDocumentByToken`;

	//#endregion


	//#region RISK REQUEST

	static STRING_PATH_CONTROLLER_RISKREQUEST: string = "/RiskRequest";

	static STRING_URL_RISKREQUEST_SELECTRISKREQUESTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/selectRiskRequestByAttributes`;
	static STRING_URL_RISKREQUEST_SELECTRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/selectRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_SUBMITRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/submitRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_SAVEALLREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/saveAllRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_INSERTRISKREQUEST: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/insertRiskRequest`;
	static STRING_URL_RISKREQUEST_SENTRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/sentRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_DELETERISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/deleteRiskRequestByToken`;

	//#endregion


	//#region DOCUMENT APPLICATION CONTROLLER

	static STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION: string = "/DocumentApplication";

	static STRING_URL_DOCUMENTAPPLICATION_INSERTDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/insertDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_SAVEDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/saveDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_UPDATEDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/updateDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_SUBMITDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/submitDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_UPDATEDOCUMENTAPPLICATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/updateDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_REJECTDOCUMENTAPPLICATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/rejectDocumentApplicationByTokenForDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_APPROVALDOCUMENTAPPLICATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/approvalDocumentApplicationByTokenForSignerDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_APPROVALDOCUMENTAPPLICATIONBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/approvalDocumentApplicationByTokenForCheckerDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_DELETEDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/deleteDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForMaker`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForChecker`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForSigner`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributes`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_DOWNLOADDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/downloadDocumentRequestAttachmentByToken`;

	//#endregion


	//#region LEGAL

	static STRING_PATH_CONTROLLER_LEGAL: string = "/Legal";

	static STRING_URL_SELECTCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEGAL}/selectChecker`;
	static STRING_URL_SELECTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEGAL}/selectSigner`;

	//#endregion


	//#region PRODUCT GENERAL DASHBOARD

	static STRING_PATH_CONTROLLER_PRODUCTGENERALDASHBOARD: string = "/ProductGeneralDashboard";

	static STRING_URL_PRODUCTGENERALDASHBOARD_SELECTPRODUCTGENERALDASHBOARDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERALDASHBOARD}/selectProductGeneralDashboardByAttributes`;

	//#endregion


	//#region UTILITY DASHBOARD

	static STRING_PATH_CONTROLLER_UTILITYDASHBOARD: string = "/UtilityDashboard";

	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINSMOBILE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINSmobile`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORINSURTECH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForInsurtech`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORMICROSITEBRIMOCROSSSELLING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForMicrositeBRImoCrossSelling`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORMICROSITESUBSIDIARYCORNERNDSUB: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForMicrositeSubsidiaryCornerNDSUB`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORJAMKRINDOSYARIAH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForJamkrindoSyariah`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINESIASYARIAH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINESIASyariah`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORKPRSBRI: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForKPRSBRI`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINSSMART: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINSSMART`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINESIAJLO: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINESIAJLO`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINESIAAKU: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINESIAAKU`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINESIACABANG: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINESIACabang`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINESIAKLAIM: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINESIAKlaim`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINESIALOGISTIK: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINESIALogistik`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORASMIKSYARIAHBSI: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForASMIKSyariahBSI`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINESIASURVEYOR: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINESIASurveyor`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINESIAKMR: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINESIAKMR`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINESIADKPB: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINESIADKPB`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINESIARENSTRA: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINESIARenstra`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINESIAUWREAS: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINESIAUWReas`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINESIABISNIS: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINESIABisnis`;
	static STRING_URL_UTILITYDASHBOARD_SELECTUTILITYDASHBOARDFORBRINESIALEGAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UTILITYDASHBOARD}/selectUtilityDashboardForBRINESIALegal`;

	//#endregion


	//#region ACCOUNTING AND FINANCE CONTROLLER

	static STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE: string = "/AccountingAndFinance";

	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTACCOUNTINGANDFINANCEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectAccountingAndFinanceByToken`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectChecker`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectSigner`;

	//#endregion


	//#region TICKET SERVICE

	static STRING_PATH_CONTROLLER_TICKETSERVICE: string = "/TicketService";

	static STRING_URL_TICKETSERVICE_SELECTTICKETSERVICEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/selectTicketServiceByAttributes`;
	static STRING_URL_TICKETSERVICE_SELECTTICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/selectTicketServiceByToken`;
	static STRING_URL_TICKETSERVICE_INSERTTICKETSERVICE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/insertTicketService`;
	static STRING_URL_TICKETSERVICE_INSERTLISTTICKETSERVICE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/insertListTicketService`;
	static STRING_URL_TICKETSERVICE_DELETETICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/deleteTicketServiceByToken`;
	static STRING_URL_TICKETSERVICE_UPDATETICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/updateTicketServiceByToken`;

	//#endregion


	//#region TICKET SERVICE AGENT

	static STRING_PATH_CONTROLLER_TICKETSERVICEAGENT: string = "/TicketServiceAgent";

	static STRING_URL_TICKETSERVICEAGENT_SELECTTICKETSERVICEAGENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/selectTicketServiceAgentByAttributes`;
	static STRING_URL_TICKETSERVICEAGENT_SELECTTICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/selectTicketServiceAgentByToken`;
	static STRING_URL_TICKETSERVICEAGENT_INSERTTICKETSERVICEAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/insertTicketServiceAgent`;
	static STRING_URL_TICKETSERVICEAGENT_INSERTLISTTICKETSERVICEAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/insertListTicketServiceAgent`;
	static STRING_URL_TICKETSERVICEAGENT_DELETETICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/deleteTicketServiceAgentByToken`;
	static STRING_URL_TICKETSERVICEAGENT_UPDATETICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/updateTicketServiceAgentByToken`;

	//#endregion


	//#region EMPLOYEE

	static STRING_PATH_CONTROLLER_EMPLOYEE: string = "/Employee";

	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByAttributes`;
	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByBranch`;
	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByDivison`;

	//#endregion


	//#region FORMATION

	static STRING_PATH_CONTROLLER_FORMATION: string = "/Formation";

	static STRING_URL_FORMATION_SELECTFORMATIONBYDIVISIONID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FORMATION}/selectFormationByDivisionID`;

	//#endregion


	//#region CUSTOMER SATISFACTION

	static STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION: string = "/CustomerSatisfaction";

	static STRING_URL_CUSTOMERSATISFACTION_SELECTCUSTOMERSATISFACTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectCustomerSatisfactionByAttributes`;
	static STRING_URL_CUSTOMERSATISFACTION_SELECTCUSTOMERSATISFACTIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectCustomerSatisfactionByToken`;
	static STRING_URL_CUSTOMERSATISFACTION_SELECTREPORTCUSTOMERSATISFACTIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectReportCustomerSatisfactionByAttributes`;
	static STRING_URL_CUSTOMERSATISFACTION_INSERTCUSTOMERSATISFACTION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/insertCustomerSatisfaction`;
	static STRING_URL_CUSTOMERSATISFACTION_DELETECUSTOMERSATISFACTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/deleteCustomerSatisfactionByToken`;

	//#endregion


	//#region OUTLOOK ARTICLE / BRINS EDITORIAL

	static STRING_PATH_CONTROLLER_OUTLOOKARTICLE: string = "/OutlookArticle";

	static STRING_URL_OUTLOOKARTICLE_SELECTOUTLOOKARTICLEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OUTLOOKARTICLE}/selectOutlookArticleByAttributes`;
	static STRING_URL_OUTLOOKARTICLE_SELECTOUTLOOKARTICLEBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OUTLOOKARTICLE}/selectOutlookArticleByToken`;
	static STRING_URL_OUTLOOKARTICLE_UPDATEOUTLOOKARTICLEBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OUTLOOKARTICLE}/updateOutlookArticleByToken`;
	static STRING_URL_OUTLOOKARTICLE_DOWNLOADOUTLOOKARTICLEPDF: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OUTLOOKARTICLE}/downloadOutlookArticlePDF`;
	static STRING_URL_OUTLOOKARTICLE_DOWNLOADOUTLOOKARTICLEGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OUTLOOKARTICLE}/downloadOutlookArticleGalleryByToken`;

	//#endregion


	//#region GCG ONLINE

	/* PROFILE COMPONENT - START */

	static STRING_PATH_CONTROLLER_GCGPROFILEPAGE: string = "/GCGProfilePage";

	static STRING_URL_GCGONLINEPROFILEPAGE_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GCGPROFILEPAGE}/SelectForEmployee`;

	/* PROFILE COMPONENT - END */

	/* GRATIFICATION LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_GRATIFICATIONLISTPAGE: string = "/GratificationListPage";

	static STRING_URL_GCGONLINEGRATIFICATIONLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONLISTPAGE}/SelectForEmployee`;

	static STRING_PATH_CONTROLLER_GRATIFICATIONEXCELPAGE: string = "/GratificationExcelPage";

	/* GRATIFICATION LIST COMPONENT - END */

	/* GRATIFICATION DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE: string = "/GratificationSubmissionPage";

	static STRING_URL_GCGONLINEGRATIFICATIONSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE}/SubmitForEmployee`;

	static STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE: string = "/GratificationDetailPage";

	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTREVIEWFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectReviewForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SUBMITREVIEWFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SubmitReviewForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTDOCUMENTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectDocumentForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/DownloadForEmployee`;

	/* GRATIFICATION DETAIL COMPONENT - END */

	/* INTEGRITY PACT LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE: string = "/IntegrityPactListPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/DownloadForEmployee`;

	static STRING_PATH_CONTROLLER_INTEGRITYPACTEXCELPAGE: string = "/IntegrityPactExcelPage";

	/* INTEGRITY PACT LIST COMPONENT - END */

	/* INTEGRITY PACT DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE: string = "/IntegrityPactSubmissionPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE}/SubmitForEmployee`;

	static STRING_PATH_CONTROLLER_INTEGRITYPACTDETAILPAGE: string = "/IntegrityPactDetailPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTDETAILPAGE}/SelectForEmployee`;

	/* INTEGRITY PACT DETAIL COMPONENT - END */

	/* CODE OF ETHICS LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE: string = "/CodeOfEthicsListPage";

	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForGCGSigner`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSEXCELPAGE: string = "/CodeOfEthicsExcelPage";

	/* CODE OF ETHICS LIST COMPONENT - END */

	/* CODE OF ETHICS DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE: string = "/CodeOfEthicsSubmissionPage";

	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SubmitForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_REVISEFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/ReviseForEmployee`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE: string = "/CodeOfEthicsDetailPage";

	static STRING_URL_GCGONLINECODEOFETHICSDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSDETAIL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE}/SelectApprovalForEmployee`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE: string = "/CodeOfEthicsApprovalPage";

	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_REJECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/RejectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_APPROVEFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/ApproveForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_REJECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/RejectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_APPROVEFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/ApproveForGCGSigner`;

	/* CODE OF ETHICS DETAIL COMPONENT - END */

	/* ANNUAL DISCLOSURE LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE: string = "/AnnualDisclosureListPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForGCGSigner`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREEXCELPAGE: string = "/AnnualDisclosureExcelPage";

	/* ANNUAL DISCLOSURE LIST COMPONENT - END */

	/* ANNUAL DISCLOSURE DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE: string = "/AnnualDisclosureSubmissionPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SubmitForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_REVISEFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/ReviseForEmployee`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE: string = "/AnnualDisclosureDetailPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTQUESTIONFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectQuestionForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectApprovalForEmployee`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE: string = "/AnnualDisclosureApprovalPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_REJECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/RejectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_APPROVEFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/ApproveForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_REJECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/RejectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_APPROVEFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/ApproveForGCGSigner`;

	/* ANNUAL DISCLOSURE DETAIL COMPONENT - END */


	//#region RISK PRODUCT SCORING

	static STRING_PATH_CONTROLLER_RISKCUSTOMERDATA: string = "/RiskCustomerData";

	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKCUSTOMERDATABYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskCustomerDataByAttributes`;

	//#endregion

	//#endregion


	//#region BRINSPRIME

	static STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE: string = "/MasterKeyPerformance";

	static STRING_URL_MASTERKEYPERFORMANCE_SELECTMASTERKEYPERFORMANCE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/selectMasterKeyPerformanceList`;
	static STRING_URL_MASTERKEYPERFORMANCE_SELECTMASTERKEYPERFORMANCEBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/selectMasterKeyPerformanceByToken`;
	static STRING_URL_MASTERKEYPERFORMANCE_SELECTMASTERKEYPEFORMANCECORPORATE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/selectMasterKeyPerformanceCorporate`;
	static STRING_URL_MASTERKEYPERFORMANCE_SELECTMASTERKEYPERFORMANCECORPORATEBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/selectMasterKeyPerformanceCorporateByToken`;
	static STRING_URL_MASTERKEYPERFORMANCE_SELECTMASTERKEYPERFORMANCEDIRECTOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/selectMasterKeyPerformanceDirector`;
	static STRING_URL_MASTERKEYPERFORMANCE_SELECTMASTERKEYPERFORMANCEDIRECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/selectMasterKeyPerformanceDirectorByToken`;
	static STRING_URL_MASTERKEYPERFORMANCE_SELECTMASTERKEYPERFORMANCECORPORATEDETAIL: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/selectMasterKeyPerformanceCorporateDetail`;

	static STRING_URL_MASTERKEYPERFORMANCE_UPDATEMASTERKEYPERFORMANCE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/updateMasterKeyPerformance`;
	static STRING_URL_MASTERKEYPERFORMANCE_UPDATEMASTERKEYPERFORMANCECORPORATE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/updateMasterKeyPerformanceCorporate`;
	static STRING_URL_MASTERKEYPERFORMANCE_UPDATEMASTERKEYPERFORMANCEDIRECTOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/updateMasterKeyPerformanceDirector`;
	static STRING_URL_MASTERKEYPERFORMANCE_UPDATEMASTERKEYPERFORMANCECORPORATEDETAIL: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/updateMasterKeyPerformanceCorporateDetail`;

	static STRING_URL_MASTERKEYPERFORMANCE_INSERTBULKMASTERKEYPERFORMANCE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/insertBulkMasterKeyPerformance`;
	static STRING_URL_MASTERKEYPERFORMANCE_INSERTBULKMASTERKEYPERFORMANCEDIRECTOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/insertBulkMasterKeyPerformanceDirector`;
	static STRING_URL_MASTERKEYPERFORMANCE_INSERTMASTERKEYPERFORMANCE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/insertMasterKeyPerformance`;
	static STRING_URL_MASTERKEYPERFORMANCE_INSERTMASTERKEYPERFORMANCECORPORATE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/insertMasterKeyPerformanceCorporate`;
	static STRING_URL_MASTERKEYPERFORMANCE_INSERTMASTERKEYPERFORMANCEDIRECTOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/insertMasterKeyPerformanceDirector`;
	static STRING_URL_MASTERKEYPERFORMANCE_INSERTMASTERKEYPERFORMANCECORPORATEDETAIL: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/insertMasterKeyPerformanceCorporateDetail`;

	static STRING_URL_MASTERKEYPERFORMANCE_SUBMITMASTERKEYPERFORMANCECORPORATEDETAIL: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/submitMasterKeyPerformanceCorporateDetail`;


	static STRING_PATH_CONTROLLERKEYPERFORMANCEMONITORING: string = "/KeyPerformanceMonitoring";

	static STRING_URL_MASTERKEYPERFORMANCE_SELECTKEYPERFORMANCEALLLISTBRANCHHEADOFFICEFORDIVISION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERKEYPERFORMANCEMONITORING}/selectKeyPerformanceAllListBranchHeadOfficeForDivision`;
	static STRING_URL_MASTERKEYPERFORMANCE_SELECTKEYPERFORMANCEALLHEADOFFICEFORDIVISION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERKEYPERFORMANCEMONITORING}/selectKeyPerformanceAllHeadOfficeForDivision`;
	static STRING_URL_KEYPERFORMANCESUBMIT_DOWNLOADKEYPERFORMANCEBRANCHMONITORING: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERKEYPERFORMANCEMONITORING}/downloadKeyPerformanceBranchMonitoring`;

	static STRING_PATH_CONTROLLERMASTERKEYPERFORMANCECORPORATE: string = "/MasterKeyPerformance";

	static STRING_URL_MASTERKEYPERFORMANCE_INSERTBULKMASTERKEYPERFORMANCECORPORATE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERMASTERKEYPERFORMANCE}/insertBulkMasterKeyPerformanceCorporate`;


	static STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION: string = "/KeyPerformanceSubmission";

	static STRING_URL_KEYPERFORMANCESUBMISSION_SELECTKEYPERFORMANCECORPORATEFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/selectKeyPerformanceCorporateForMaker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_INSERTKEYPERFORMANCECORPORATEFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/insertKeyPerformanceCorporateForMaker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_SELECTKEYPERFORMANCECORPORATEFORMAKERBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/selectKeyPerformanceCorporateForMakerByToken`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_UPDATEKEYPERFORMANCECORPORATEFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/updateKeyPerformanceCorporateForMaker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_SUBMITKEYPERFORMANCECORPORATEFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/submitKeyPerformanceCorporateForMaker`;

	static STRING_URL_KEYPERFORMANCESUBMISSION_SELECTKEYPERFORMANCEDIRECTORFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/selectKeyPerformanceDirectorForMaker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_INSERTKEYPERFORMANCEDIRECTORFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/insertKeyPerformanceDirectorForMaker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_SELECTKEYPERFORMANCEDIRECTORFORMAKERBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/selectKeyPerformanceDirectorForMakerByToken`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_UPDATEKEYPERFORMANCEDIRECTORFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/updateKeyPerformanceDirectorForMaker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_SUBMITKEYPERFORMANCEDIRECTORFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/submitKeyPerformanceDirectorForMaker`;

	static STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL: string = "/KeyPerformanceCorporateApproval";

	static STRING_URL_KEYPERFORMANCESUBMISSION_SELECTKEYPERFORMANCECORPORATEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/selectKeyPerformanceCorporateForChecker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_APPROVEKEYPERFORMANCECORPORATEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/approveKeyPerformanceCorporateForChecker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_REJECTKEYPERFORMANCECORPORATEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/rejectKeyPerformanceCorporateForChecker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_SELECTKEYPERFORMANCECORPORATEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/selectKeyPerformanceCorporateForSigner`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_REJECTKEYPERFORMANCECORPORATEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/rejectKeyPerformanceCorporateForSigner`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_APPROVEKEYPERFORMANCECORPORATEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/approveKeyPerformanceCorporateForSigner`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_SELECTKEYPERFORMANCECORPORATEAPPROVALFORDIVISION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/selectKeyPerformanceCorporateApprovalForDivision`;

	static STRING_URL_CONTROLLER_KEYPERFORMANCESUBMIT: string = "/KeyPerformanceSubmit";

	static STRING_URL_KEYPERFORMANCESUBMIT_SELECTKEYPERFORMANCECORPORATESUBMITFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMIT}/selectKeyPerformanceCorporateSubmitForMaker`;
	static STRING_URL_KEYPERFORMANCESUBMIT_UPDATEKEYPERFORMANCECORPORATESUBMITFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMIT}/updateKeyPerformanceCorporateSubmitForMaker`;


	static STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEMONITORING: string = "/KeyPerformanceCorporateMonitoring";

	static STRING_URL_KEYPERFORMANCESUBMIT_SELECTKEYPERFORMANCECORPORATEMONITORING: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEMONITORING}/selectKeyPerformanceCorporateMonitoring`;
	static STRING_URL_KEYPERFORMANCESUBMIT_DOWNLOADKEYPERFORMANCECORPORATEMONITORING: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEMONITORING}/downloadKeyPerformanceCorporateMonitoring`;


	static STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORAPPROVAL: string = "/KeyPerformanceDirectorApproval";

	static STRING_URL_KEYPERFORMANCEDIRECTORAPPROVAL_SELECTKEYPERFORMANCEDIRECTORFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORAPPROVAL}/selectKeyPerformanceDirectorForChecker`;
	static STRING_URL_KEYPERFORMANCEDIRECTORAPPROVAL_APPROVEKEYPERFORMANCEDIRECTORFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORAPPROVAL}/approveKeyPerformanceDirectorForChecker`;
	static STRING_URL_KEYPERFORMANCEDIRECTORAPPROVAL_REJECTKEYPERFORMANCEDIRECTORFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORAPPROVAL}/rejectKeyPerformanceDirectorForChecker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_SELECTKEYPERFORMANCEDIRETORAPPROVALFORDIVISION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORAPPROVAL}/selectKeyPerformanceDirectorApprovalForDivision`;
	static STRING_URL_KEYPERFORMANCEDIRECTORAPPROVAL_SELECTKEYPERFORMANCEDIRECTORFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORAPPROVAL}/selectKeyPerformanceDirectorForSigner`;
	static STRING_URL_KEYPERFORMANCEDIRECTORAPPROVAL_APPROVEKEYPERFORMANCEDIRECTORFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORAPPROVAL}/approveKeyPerformanceDirectorForSigner`;
	static STRING_URL_KEYPERFORMANCEDIRECTORAPPROVAL_REJECTKEYPERFORMANCEDIRECTORFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORAPPROVAL}/rejectKeyPerformanceDirectorForSigner`;


	static STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORSUBMIT: string = "/KeyPerformanceDirectorSubmit";

	static STRING_URL_KEYPERFORMANCEDIRECTORSUBMIT_SELECTKEYPERFORMANCEDIRECTORSUBMITFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORSUBMIT}/selectKeyPerformanceDirectorSubmitForMaker`;
	static STRING_URL_KEYPERFORMANCEDIRECTORSUBMIT_UPDATEKEYPERFORMANCEDIRECTORSUBMITFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORSUBMIT}/updateKeyPerformanceDirectorSubmitForMaker`;

	static STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORMONITORING: string = "/KeyPerformanceDirectorMonitoring";

	static STRING_URL_KEYPERFORMANCEDIRECTORMONITORING_SELECTKEYPERFORMANCEDIRECTORMONITORING: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORMONITORING}/selectKeyPerformanceDirectorMonitoring`;
	static STRING_URL_KEYPERFORMANCESUBMIT_DOWNLOADKEYPERFORMANCEDIRECTORMONITORING: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCEDIRECTORMONITORING}/downloadKeyPerformanceDirectorMonitoring`;

	//#endregion


	//#region BRINSPRO

	static STRING_PATH_CONTROLLERPROJECTMANAGEMENT: string = "/ProjectManagement";

	static STRING_URL_SELECTPROJECTMANAGEMENTLISTFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENT}/selectProjectManagementListForMaker`;
	static STRING_URL_SELECTPROJECTMANAGEMENTLBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENT}/selectProjectManagementByToken`;

	static STRING_URL_INSERTPROJECTMANAGEMENTFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENT}/insertProjectManagementForMaker`;
	static STRING_URL_UPDATEPROJECTMANAGEMENTFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENT}/updateProjectManagementForMaker`;

	static STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL: string = "/ProjectManagementApproval";

	static STRING_URL_SELECTPROJECTMANAGEMENTLISTFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/selectProjectManagementListForChecker`;
	static STRING_URL_SELECTPROJECTMANAGEMENTLISTFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/selectProjectManagementListForSigner`;
	static STRING_URL_APPROVEPROJECTMANAGEMENTFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/approveProjectManagementForChecker`;
	static STRING_URL_REVICEPROJECTMANAGEMENTFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/reviseProjectManagementForChecker`;
	static STRING_URL_APPROVEPROJECTMANAGEMENTFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/approveProjectManagementForSigner`;
	static STRING_URL_REVICEPROJECTMANAGEMENTFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/reviseProjectManagementForSigner`;
	static STRING_URL_SELECTPROJECTMANAGEMENTAPPROVALBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/selectProjectManagementApprovalByToken`;

	static STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT: string = "/ProjectManagementDocument";

	static STRING_URL_SELECTPROJECTMANAGEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/selectProjectManagementDocumentList`;
	static STRING_URL_SELECTPROJECTMANAGEMENTDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/selectProjectManagementDocumentByToken`;
	static STRING_URL_INSERTPROJECTMANAGEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/insertProjectManagementDocument`;
	static STRING_URL_UPDATEPROJECTMANAGEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/updateProjectManagementDocument`;
	static STRING_URL_DOWNLOADPROJECTMANAGEMENTDOCUMENTDETAILBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/downloadProjectManagementDocumentDetailByToken`;

	static STRING_PATH_CONTROLLERPROJECTMANAGEMENTDASHBOARD: string = "/ProjectManagementDashboard";

	static STRING_URL_SELECTPROJECTMANAGEMENTDASHBOARD: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDASHBOARD}/selectProjectManagementDashboard`;

	//#endregion


	//#region TAX REGULATION

	static STRING_PATH_CONTROLLER_TAXREGULATION: string = "/TaxRegulation";

	static STRING_URL_TAXREGULATION_SELECTTAXREGULATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/selectTaxRegulationByToken`;
	static STRING_URL_TAXREGULATION_SELECTTAXREGULATIOBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/selectTaxRegulationsByAttribute`;
	static STRING_URL_TAXREGULATION_INSERTTAXREGULATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/insertTaxRegulation`;
	static STRING_URL_TAXREGULATION_UPDATETAXREGULATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/updateTaxRegulation`;
	static STRING_URL_TAXREGULATION_DOWNLOADTAXREGULATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/downloadTaxRegulationByToken`;
	static STRING_URL_TAXREGULATION_UPDATESTATETAXREGULATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/updateStateTaxRegulation`;

	//#endregion
}

//#endregion
