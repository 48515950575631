//#region CONSTANT

export class StringConstant
{
	//#region REGULUAR_EXPRESSION

	static STRING_REGULAREXPRESSION_PERSONNAME: string = "^[A-Za-z\\s]{1,}[\\.]{0," + "1}[A-Za-z\\s]{0,}$";
	static STRING_REGULAREXPRESSION_ALPHABET: string = "^[a-zA-Z]+$";
	static STRING_REGULAREXPRESSION_NUMERIC: string = "^[0-9]*$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERIC: string = "^[a-zA-Z0-9]+$";
	static STRING_REGULAREXPRESSION_EMAIL: string = "[a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256}\\@[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}(\\.[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25})+";
	static STRING_REGULAREXPRESSION_MOBILEPHONEWITHPLUS: string = "^[+]?[0-9]{10,15}$";
	static STRING_REGULAREXPRESSION_DATEISO8601: string = "[0-9]{4}-[0-9]{2}-[0-9]{1,2}";
	static STRING_REGULAREXPRESSION_CURRENCY: string = "[^0-9]+[\\.]";
	static STRING_REGULAREXPRESSION_EMAILWITHOUTSPACE: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
	static STRING_REGULAREXPRESSION_DATA_NUMBER: RegExp = /\B(?=(\d{3})+(?!\d))/g;

	//#endregion


	//#region LANGUAGE_CULTURE

	static STRING_CULTURE_INDONESIAN: string = "id-ID";
	static STRING_CULTURE_ENGLISH: string = "en-US";

	static STRING_LANGUAGE_INDONESIAN: string = "ID";
	static STRING_LANGUAGE_ENGLISH: string = "EN";

	//#endregion


	//#region CHARACTER

	static STRING_CHARACTER_SEPARATOR_DATE_VIEW: string = "/";
	static STRING_CHARACTER_SEPARATOR_DATE_SERVER: string = "-";
	static STRING_CHARACTER_SEPARATOR_DATETIME_SERVER: string = "'T'";
	static STRING_CHARACTER_SEPARATOR_TIME_VIEW: string = ":";
	static STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW: string = "Z";
	static STRING_CHARACTER_SEPARATOR_DECIMAL: string = ";";
	static STRING_CHARACTER_SEPARATOR_THOUSAND: string = ".";
	static STRING_CHARACTER_DASH: string = "-";
	static STRING_CHARACTER_EMPTY: string = " ";

	static STRING_CHARACTER_SEPARATOR_FORMAT: string = ".";
	static STRING_CHARACTER_SEPARATOR_DIRECTORY: string = "/";
	static STRING_CHARACTER_SEPARATOR_FILENAME: string = "_";
	static STRING_CHARACTER_SEPARATOR_PERCENTAGE: string = "%";

	//#endregion


	//#region TAG

	static STRING_TAG_VERIFY_IDENTITYCARD: string = "PRODUCTASRI_";
	static STRING_TAG_VERIFY_SELFIEIDENTITYCARD: string = "PRODUCTOTO_";

	//#endregion


	//#region DATE

	static STRING_DATE_SERVER_DEFAULT: string = "0000" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00";
	static STRING_TIME_SERVER_DEFAULT: string = "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_DEFAULT: string = StringConstant.STRING_DATE_SERVER_DEFAULT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_DEFAULT;
	static NUMBER_TIME_SERVER_COMPONENT: number = 3;

	static STRING_DATE_SERVER_FORMAT: string = "yyyy-MM-dd";
	static STRING_TIME_SERVER_FORMAT: string = "HH:mm:ss";
	static STRING_DATETIME_SERVER_FORMAT: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_FORMATWITHOUTTIMEZONE: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT;

	static STRING_DATE_VIEW_FORMAT: string = "dd/MM/yyyy";
	static STRING_TIME_VIEW_FORMAT: string = "HH:mm:ss";
	static STRING_TIME_VIEW_FORMATSHORT: string = "HH:mm";
	static STRING_DATETIME_VIEW_FORMAT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMAT;
	static STRING_DATETIME_VIEW_FORMATSHORT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMATSHORT;
	static STRING_TIMEZONE_VIEW_FORMAT: string = "GMT+07:00";

	static STRING_CURRENCY_IDR_CODE: string = "IDR";
	static STRING_CURRENCY_IDR_PREFIX: string = "Rp. ";
	static STRING_CURRENCY_THOUSANDS: string = "K. ";
	static STRING_CURRENCY_KILO: string = " Km";

	static STRING_DATE_SHORT_FORMAT = "d-M-yyyy";
	static STRING_DATE_MEDIUM_FORMAT = "d MMMM y";
	static STRING_DATE_LONG_FORMAT = "EEEE, d MMMM y";

	static STRING_TIME_SHORT_FORMAT = "HH:mm";
	static STRING_TIME_MEDIUM_FORMAT = "HH:mm:ss";
	static STRING_TIME_LONG_FORMAT = "HH:mm:ss z";

	static STRING_DATETIME_COMPLETE_FORMAT = "EEEE, d MMMM y | HH:mm:ss";

	//#endregion


	//#region KEY_VALUE

	static STRING_VALUE_YEAR_MINIMUM: string = "1900";
	static STRING_VALUE_APPLICATION_VERSION: string = "1.12.0";
	static STRING_VALUE_CLIENT_VERSION: string = "1.1:10";
	static STRING_VALUE_PRODUCT_ASRI: string = "0117";
	static STRING_VALUE_PRODUCT_OTO: string = "0206";
	static STRING_VALUE_PRODUCT_PERSONALACCIDENT: string = "1001";
	static STRING_VALUE_PACKAGE_ASRISILVER: string = "ASRI-01";
	static STRING_VALUE_PACKAGE_ASRIGOLD: string = "ASRI-02";
	static STRING_VALUE_PACKAGE_ASRIPLATINUM: string = "ASRI-03";
	static STRING_VALUE_PACKAGE_OTOBRONZE: string = "OTO-01";
	static STRING_VALUE_PACKAGE_OTOSILVER: string = "OTO-02";
	static STRING_VALUE_PACKAGE_OTOGOLD: string = "OTO-03";
	static STRING_VALUE_PACKAGE_OTOPLATINUM: string = "OTO-04";
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTBM: string = "PA-BM";
	static STRING_VALUE_PRODUCT_ASRI_SYARIAH: string = "0164";
	static STRING_VALUE_PRODUCT_OTO_SYARIAH: string = "0256";
	static STRING_VALUE_PRODUCT_PERSONALACCIDENT_SYARIAH: string = "1051";
	static STRING_VALUE_PACKAGE_ASRISILVER_SYARIAH: string = "ASRI-01-S";
	static STRING_VALUE_PACKAGE_ASRIGOLD_SYARIAH: string = "ASRI-02-S";
	static STRING_VALUE_PACKAGE_ASRIPLATINUM_SYARIAH: string = "ASRI-03-S";
	static STRING_VALUE_PACKAGE_OTOBRONZE_SYARIAH: string = "OTO-01-S";
	static STRING_VALUE_PACKAGE_OTOSILVER_SYARIAH: string = "OTO-02-S";
	static STRING_VALUE_PACKAGE_OTOGOLD_SYARIAH: string = "OTO-03-S";
	static STRING_VALUE_PACKAGE_OTOPLATINUM_SYARIAH: string = "OTO-04-S";
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTBM_SYARIAH: string = "PA-BM-S";

	static STRING_VALUE_FILENAME_CALCULATEPREMIUM: string = "calculatepremium";
	static STRING_VALUE_FILENAME_SPPA: string = "sppa";
	static STRING_VALUE_FILENAME_POLICYOUTSTANDING: string = "policyoutstanding";
	static STRING_VALUE_FILENAME_POLICYRENEWAL: string = "policyrenewal";

	static STRING_VALUE_PRINTJOB_PDFPREVIEW: string = "Anjani - PDF Preview";

	static STRING_VALUE_COUNTRYCODE_INDONESIA: string = "+62";
	static STRING_VALUE_COUNTRYCODE_VIETNAM: string = "+84";
	static STRING_VALUE_COUNTRYCODE_NEWJERSEY: string = "+856";
	static STRING_VALUE_COUNTRYCODE_SINGAPORE: string = "+65";
	static STRING_VALUE_COUNTRYCODE_MALAYSIA: string = "+60";
	static STRING_VALUE_COUNTRYCODE_BRUNEI: string = "+673";
	static STRING_VALUE_COUNTRYCODE_THAILAND: string = "+66";
	static STRING_VALUE_COUNTRYCODE_MYANMAR: string = "+95";
	static STRING_VALUE_COUNTRYCODE_PHILIPPINES: string = "+63";
	static STRING_VALUE_COUNTRYCODE_CAMBODIA: string = "+855";
	static STRING_VALUE_TELEPHONE_BRINS_PRIMARY: string = "(21)79170477";
	static STRING_VALUE_TELEPHONE_BRINS_SECONDARY: string = "(21)79170478";
	static STRING_VALUE_TELEPHONE_CALLCENTER: string = "14081";

	static ARRAY_VALUE_COUNTRYCODE: Array<string> = [
		StringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA, StringConstant.STRING_VALUE_COUNTRYCODE_VIETNAM ,StringConstant.STRING_VALUE_COUNTRYCODE_NEWJERSEY, StringConstant.STRING_VALUE_COUNTRYCODE_SINGAPORE,
		StringConstant.STRING_VALUE_COUNTRYCODE_MALAYSIA, StringConstant.STRING_VALUE_COUNTRYCODE_BRUNEI, StringConstant.STRING_VALUE_COUNTRYCODE_THAILAND, StringConstant.STRING_VALUE_COUNTRYCODE_MYANMAR,
		StringConstant.STRING_VALUE_COUNTRYCODE_PHILIPPINES, StringConstant.STRING_VALUE_COUNTRYCODE_CAMBODIA];

	static STRING_CHART_PIE_LEGENDPOSITION: string = "below";
	static STRING_CHART_BAR_LEGENDPOSITION: string = "right";
	static STRING_FIELD_EMAIL_WORK_BRINS: string = "@work.brins.co.id";
	static STRING_FIELD_EMAIL_BRINS: string = "@brins.co.id";
	static STRING_FIELD_EMAIL_TSI_BRINS: string = "tsi@brins.co.id";
	static STRING_FIELD_EMAIL_INFO_BRINS: string = "callcenter@brins.co.id";
	static STRING_FIELD_EMAIL_OPS_TSI: string = "ops-tsi@work.brins.co.id";
	static STRING_FIELD_BRANCHCODE_HEADOFFICE: string = "100";
	static STRING_FIELD_BRANCHCODE_HEADQUARTER: string = "115";
	static STRING_FIELD_MODELVEHICLEBYVEHICLEBRAND: string = "ADI";
	static STRING_POSITION_TOP: string = "top";
	static STRING_POSITION_RIGHT: string = "right";
	static STRING_POSITION_BOTTOM: string = "bottom";
	static STRING_POSITION_LEFT: string = "left";
	static STRING_POSITION_AUTO: string = "auto";

	//#endregion


	//#region IMAGE

	static STRING_FORMAT_IMAGE_JPG: string = "jpg";
	static STRING_FORMAT_COMPRESS_ZIP: string = "zip";
	static STRING_FORMAT_DOCUMENT_PDF: string = "pdf";
	static STRING_FORMAT_COMPRESS_RAR: string = "rar";
	static STRING_FORMAT_IMAGE_PNG: string = "png";
	static STRING_FORMAT_IMAGE_JPEG: string = "jpeg";
	static STRING_FORMAT_DOCUMENT_WORD: string = "doc";
	static STRING_FORMAT_DOCUMENT_WORDX: string = "docx";
	static STRING_FORMAT_DOCUMENT_EXCEL: string = "xls";
	static STRING_FORMAT_DOCUMENT_EXCELX: string = "xlsx";
	static STRING_FORMAT_DOCUMENT_CSV: string = "csv";
	static STRING_FORMAT_DOCUMENT_POWERPOINT: string = "ppt";
	static STRING_FORMAT_DOCUMENT_POWERPOINTX: string = "pptx";
	static STRING_FORMAT_IMAGE_SVG: string = "svg";

	static ARRAY_VALUE_FORMAT: Array<string> = [
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_DOCUMENT_PDF, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG, StringConstant.STRING_FORMAT_DOCUMENT_WORD, StringConstant.STRING_FORMAT_DOCUMENT_WORDX, StringConstant.STRING_FORMAT_DOCUMENT_EXCEL, StringConstant.STRING_FORMAT_DOCUMENT_EXCELX, StringConstant.STRING_FORMAT_DOCUMENT_CSV];
	static ARRAY_VALUE_FORMAT_FOR_ASSESSMENTGUIDE: Array<string> = [
		// eslint-disable-next-line max-len
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_DOCUMENT_PDF, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG, StringConstant.STRING_FORMAT_DOCUMENT_WORD, StringConstant.STRING_FORMAT_DOCUMENT_WORDX, StringConstant.STRING_FORMAT_DOCUMENT_EXCEL, StringConstant.STRING_FORMAT_DOCUMENT_EXCELX, StringConstant.STRING_FORMAT_DOCUMENT_CSV, StringConstant.STRING_FORMAT_DOCUMENT_POWERPOINT, StringConstant.STRING_FORMAT_DOCUMENT_POWERPOINTX];

	static ARRAY_VALUE_FORMAT_EMATERAI: Array<string> = [
		StringConstant.STRING_FORMAT_DOCUMENT_PDF];

	static ARRAY_VALUE_FORMAT_GCGONLINE: Array<string> = [
		// eslint-disable-next-line max-len
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_IMAGE_JPEG, StringConstant.STRING_FORMAT_DOCUMENT_PDF, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_DOCUMENT_WORD, StringConstant.STRING_FORMAT_DOCUMENT_WORDX, StringConstant.STRING_FORMAT_DOCUMENT_EXCEL, StringConstant.STRING_FORMAT_DOCUMENT_EXCELX, StringConstant.STRING_FORMAT_DOCUMENT_CSV, StringConstant.STRING_FORMAT_COMPRESS_ZIP, StringConstant.STRING_FORMAT_COMPRESS_RAR];

	//#endregion


	//#region CONVERTION

	static STRING_HASH_SHA256: string = "SHA-256";

	//#endregion


	//#region HTTP

	static STRING_HTTP_HEADER_AUTHORIZEDKEY: string = "AuthorizedKey";

	//#endregion


	//#region COMPLIENCE AND RISK MANAGEMENT

	static STRING_ACCESS_USER_RISKCHAMPION = "Risk Champion";
	static STRING_ACCESS_USER_RISKAPPROVER = "Risk Approver";

	//#endregion


	//#region GCG ONLINE

	static STRING_ACCESS_USER_EMPLOYEE = "Employee";
	static STRING_ACCESS_USER_GCGADMIN = "GCG Admin";
	static STRING_ACCESS_USER_GCGCHECKER = "GCG Checker";
	static STRING_ACCESS_USER_GCGSIGNER = "GCG Signer";

	//#endregion


	//#region ROUTING

	static STRING_ROUTING_KEY_TOKEN: string = "token";
	static STRING_ROUTING_POLICYNUMBER: string = "policynumber";
	static STRING_ROUTING_CLAIMNUMBER: string = "claimnumber";
	static STRING_ROUTING_PAYMENTANO: string = "ano";
	static STRING_ROUTING_PUBLIC: string = "public";
	static STRING_ROUTING_TICKET: string = "ticket";
	static STRING_ROUTING_SIGNIN: string = "signin";

	//#endregion


	//#region LOCAL STORAGE

	static STRING_LOCALSTORAGE_KEY_HANDSHAKEMODEL: string = "HandshakeModel";
	static STRING_LOCALSTORAGE_KEY_USERSESSION: string = "UserSession";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALASRILIST: string = "TableModelForApprovalAsriList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALOTOLIST: string = "TableModelForApprovalOtoList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEASRILIST: string = "TableModelForPurchaseAsriList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEOTOLIST: string = "TableModelForPurchaseOtoList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEPERSONALACCIDENTLIST: string = "TableModelForPurchasePersonalAccidentList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPACKAGELIST: string = "TableModelForPackageList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPACKAGEOPTIONLIST: string = "TableModelForPackageOptionList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORCHATREPORTLIST: string = "TableModelForChatReportList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORBANREPORTLIST: string = "TableModelForBanReportList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORADVERTISINGDAILYLIST: string = "TableModelForAdvertisingDailyList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORADVERTISINGCARDLIST: string = "TableModelForAdvertisingCardList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORACCOUNTLIST: string = "TableModelForAccountList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORACCOUNTOPTIONLIST: string = "TableModelForAccountOptionList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORSEEKERAPPROVALLIST: string = "TableModelForSeekerApprovalList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORSEEKERPROFILEREPORTLIST: string = "TableModelForSeekerProfileReportList";

	//#endregion

	//#region DASHBOARD UTILITY REPORT

	static STRING_LABEL_DASHBOARD_UTILITY_LOGIN: string = "Login";
	static STRING_LABEL_DASHBOARD_UTILITY_TRAFFICPENGGUNAAN: string = "Traffic Penggunaan";

	static STRING_VALUE_DASHBOARD_JAMKRINDOSYARIAH: string = "Jamkrindo Syariah";
	static STRING_VALUE_DASHBOARD_BRINESIASYARIAH: string = "BRINESIA Syariah";
	static STRING_VALUE_DASHBOARD_KPRSBRI: string = "KPRS BRI";
	static STRING_VALUE_DASHBOARD_BRINSMART: string = "BRINSMART";
	static STRING_VALUE_DASHBOARD_BRINESIAJLO: string = "BRINESIA JLO";
	static STRING_VALUE_DASHBOARD_BRINESIAAKU: string = "BRINESIA AKU";
	static STRING_VALUE_DASHBOARD_BRINESIACABANG: string = "BRINESIA Cabang";
	static STRING_VALUE_DASHBOARD_BRINESIAKLAIM: string = "BRINESIA Klaim";
	static STRING_VALUE_DASHBOARD_BRINESIALOGISTIK: string = "BRINESIA Logistik";
	static STRING_VALUE_DASHBOARD_BRINESIABIS: string = "BRINESIA BIS";
	static STRING_VALUE_DASHBOARD_ASMIKSYARIAHBSI: string = "ASMIK Syariah BSI";
	static STRING_VALUE_DASHBOARD_BRINESIASURVEYOR: string = "BRINESIA Surveyor";
	static STRING_VALUE_DASHBOARD_BRINSMOBILE: string = "BRINS Mobile";
	static STRING_VALUE_DASHBOARD_BRINSWEBSITE: string = "BRINS Website";
	static STRING_VALUE_DASHBOARD_BRINESIAKMR: string = "BRINESIA KMR";
	static STRING_VALUE_DASHBOARD_BRINESIADKPB: string = "BRINESIA DKPB";
	static STRING_VALUE_DASHBOARD_BRINESIARENSTRA: string = "BRINESIA Renstra";
	static STRING_VALUE_DASHBOARD_BRINESIAUWREAS: string = "BRINESIA UW Reas";
	static STRING_VALUE_DASHBOARD_BRIMOCROSSSELLING: string = "BRIMO Cross Selling";
	static STRING_VALUE_DASHBOARD_NEWDELIVERYSYSTEM: string = "New Delivery System";
	static STRING_VALUE_DASHBOARD_BRINESIABISNIS: string = "BRINESIA Bisnis";
	static STRING_VALUE_DASHBOARD_BRINESIALEGAL: string = "BRINESIA Legal";

	static ARRAY_VALUE_DASHBOARD_UTILITY_REPORT: Array<string> =
		[
			this.STRING_VALUE_DASHBOARD_JAMKRINDOSYARIAH,this.STRING_VALUE_DASHBOARD_BRINESIASYARIAH, this.STRING_VALUE_DASHBOARD_KPRSBRI, this.STRING_VALUE_DASHBOARD_BRINSMART, this.STRING_VALUE_DASHBOARD_BRINESIAJLO,
		 	this.STRING_VALUE_DASHBOARD_BRINESIAAKU, this.STRING_VALUE_DASHBOARD_BRINESIACABANG, this.STRING_VALUE_DASHBOARD_BRINESIAKLAIM, this.STRING_VALUE_DASHBOARD_BRINESIALOGISTIK,
			// this.STRING_VALUE_DASHBOARD_BRINESIABIS,
			this.STRING_VALUE_DASHBOARD_ASMIKSYARIAHBSI, this.STRING_VALUE_DASHBOARD_BRINESIASURVEYOR, this.STRING_VALUE_DASHBOARD_BRINESIAKMR,
			this.STRING_VALUE_DASHBOARD_BRINESIADKPB, this.STRING_VALUE_DASHBOARD_BRINESIARENSTRA, this.STRING_VALUE_DASHBOARD_BRINESIAUWREAS,
			this.STRING_VALUE_DASHBOARD_BRINESIABISNIS, this.STRING_VALUE_DASHBOARD_BRINESIALEGAL
		];

	//#endregion


	//#region CHECK URL

	static STRING_URL_PATH_INSERT: string = "insert";
	static STRING_URL_PATH_CHECKER: string = "checker";
	static STRING_URL_PATH_SIGNER: string = "signer";
	static STRING_URL_PATH_MAKER: string = "maker";
	static STRING_URL_PATH_DETAIL: string = "detail";
	static STRING_URL_PATH_UPDATE: string = "update";
	static STRING_URL_PATH_ASSESS: string = "assess";

	//#endregion


	//#region NAME PRODUCT CONTROLLER

	static STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRI: string = "BRINS ASRI";
	static STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTO: string = "BRINS OTO";
	static STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_BICYCLE: string = "BRINS Sepeda";
	static STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT: string = "BRINS DIRI";
	static STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_SHOPHOUSE: string = "BRINS RUKO";
	static STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_APARTMENT: string = "BRINS APARTEMEN";
	static STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACE: string = "ASMIK KTU";
	static STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSE: string = "ASMIK RumahKu";
	static STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYMOTORCYCLE: string = "ASMIK MotorKu";

	static ARRAY_VALUE_PRODUCT_NAME_PROMOCODE: Array<string> =
		[
			this.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRI,
			this.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTO,
			this.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT,
			this.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_BICYCLE
		];

	static ARRAY_VALUE_PRODUCT_NAME: Array<string> =
		[
			this.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_ASRI,
			this.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_OTO,
			this.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_BICYCLE,
			this.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_PERSONALACCIDENT,
			this.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_DAMAGEOFBUSINESSPLACE,
			this.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYHOUSE,
			this.STRING_PRODUCTGENERALREPORT_ATTRIBUTE_VALUE_PRODUCTNAME_MYMOTORCYCLE
		];

	//#endregion


	//#region OVERVIEW DASHBOARD CHARTS

	static STRING_BARCHART_ASRISCHEME: string = "Bar Chart Asri Scheme";
	static STRING_BARCHART_OTOSCHEME: string = "Bar Chart OTO Scheme";
	static STRING_BARCHART_PERSONALACCIDENTSCHEME: string = "Bar Chart Personal Accident Scheme";

	static STRING_VALUE_PACKAGE_PLATINUM: string = "Platinum";
	static STRING_VALUE_PACKAGE_GOLD: string = "Gold";
	static STRING_VALUE_PACKAGE_SILVER: string = "Silver";
	static STRING_VALUE_PACKAGE_BRONZE: string = "Bronze";

	static ARRAY_VALUE_DOMAIN_ASRI: Array<string> =
		[
			"#2681B5", "#F79421", "#BCBEC0", "#DD5858"
		];

	static ARRAY_VALUE_DOMAIN_OTO: Array<string> =
		[
			"#2681B5", "#F79421", "#BCBEC0", "#A97C50", "#DD5858", "#39B54A", "#009444", "#006838"
		];

	static ARRAY_VALUE_DOMAIN_PERSONALACCIDENT: Array<string> =
		[
			"#A7473E", "#DD5858", "#D34D53"
		];

	//#endregion


	//#region CHANNEL

	static STRING_CHANNEL_BRINSWEBSITE: string = "Insurtech";
	static STRING_CHANNEL_BRINSMOBILE: string = "BRINSmobile";
	static STRING_CHANNEL_BRIMO: string = "Microsite BRImo Cross Selling";
	static STRING_CHANNEL_NDSUB: string = "Microsite Subsidiary Corner NDS UB";
	static STRING_CHANNEL_SELFSERVICEMONITORING: string = "Self Service Monitor (KiosK)";

	static ARRAY_VALUE_CHANNEL_NAME: Array<string> =
		[
			this.STRING_CHANNEL_BRINSWEBSITE,
			this.STRING_CHANNEL_BRINSMOBILE,
			this.STRING_CHANNEL_BRIMO,
			this.STRING_CHANNEL_NDSUB,
			this.STRING_CHANNEL_SELFSERVICEMONITORING
		];

	//#endregion


	//#region CONFIRMATION ANSWER

	static STRING_CONFIRMATION_ADD_STAMPING_EMATERAI = "are you sure you want to add this ?";
	static STRING_CONFIRMATION_UPDATE_STAMPING_EMATERAI = "are you sure you want to update this ?";
	static STRING_CONFIRMATION_APPROVAL_STAMPING_EMATERAI = "are you sure you want to approve this ?";
	static STRING_CONFIRMATION_REJECT_STAMPING_EMATERAI = "are you sure you want to reject this ?";

	//#endregion


	//#region VALIDATION

	static STRING_VALIDATION_STRING_EMPTY: string = "";
	static STRING_VALIDATION_STRING_SPACE: string = " ";

	//#endregion


	//#region EXPORT

	static STRING_EXPORT_EXCEL_TITLE: string = "Ekspor Excel";
	static STRING_EXPORT_EXCEL_SUCCESS: string = "Unduh file excel berhasil!";
	static STRING_EXPORT_EXCEL_SHEET_1: string = "Sheet1";
	static STRING_EXPORT_EXCEL_FORMAT_TICKETREPORT: string = "Ticket Report.xlsx";
	static STRING_EXPORT_EXCEL_FORMAT_TICKETSATISFACTIONREPORT: string = "Ticket Satisfaction Report.xlsx";

	//#endregion


	//#region CHART

	static STRING_CHART_LABEL_FORMATER_BAR: string = "{a}";
	static STRING_CHART_DEFAULT_XAXIS_LABEL: string = "X-axis Label";
	static STRING_CHART_DEFAULT_YAXIS_LABEL: string = "Y-axis Label";
	static STRING_CHART_RESULT_ITEM_NAME_EMPTY: string = "Empty";
	static STRING_CHART_LEGEND_TYPE_SCROLL: string = "scroll";
	static STRING_CHART_THEME_SECONDARY_DARK: string = "#F26F21";

	//#endregion


	//#region ENUM

	static STRING_ENUM_AUDIT_ANOMALYQUARTER: string = "ENUM_ANOMALY_QUARTER";
	static STRING_ENUM_ANOMALY_ANALYSISMETHOD: string = "ENUM_ANOMALY_ANALYSISMETHOD";
	static STRING_ENUM_ANOMALY_CATEGORY: string = "ENUM_ANOMALY_CATEGORY";
	static STRING_ENUM_DOCUMENT_TYPE: string = "ENUM_DOCUMENT_TYPE";
	static STRING_ENUM_CATEGORY_TYPE: string = "ENUM_CATEGORY_TYPE";
	static STRING_ENUM_TAXDOCUMENT_TYPE: string = "ENUM_TAXDOCUMENT_TYPE";

	//#endregion


	//#region TAX REGULATION

	static STRING_ACCESS_USER_AKUTAXREGULATION = "Tax Regulation";

	//#endregion
}

//#endregion
