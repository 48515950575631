//#region IMPORT

import { Router } from "@angular/router";
import { PartialObserver } from "rxjs";
import { StrategicAndPlanningModel } from "src/app/models/strategicandplanning.model";
import { UserModel } from "src/app/models/user.model";
import { SessionService } from "src/app/services/session.service";
import { EnumGeneralModel } from "src/app/models/enumgeneral.model";
import { StringConstant } from "src/app/constants/string.constant";
import { ENUM_CATEGORY_TYPE, ENUM_DOCUMENT_TYPE } from "src/app/constants/enum.constant";
import { ConvertSeparateEnumSpace } from "src/app/functions/pipes/converter.pipe";
import { BasePublicComponent } from "./basepublic.component";

//#endregion


//#region CLASS

export class BaseAuthourizeComponent extends BasePublicComponent
{
	//#region DECLARATION

	public _booleanReadOnly: boolean;
	public _modelUserSignIn: UserModel;
	public _modelStrategicAndPlanningSignIn: StrategicAndPlanningModel;
	public _modelNull: any = null;
	public _modelUndefined: any = undefined;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._modelUserSignIn = new UserModel();
		this._modelStrategicAndPlanningSignIn = new StrategicAndPlanningModel();
		this._booleanReadOnly = true;

		const observerPartialModelUserSignIn: PartialObserver<UserModel> =
		{
			next: (modelUser: UserModel) => { this._modelUserSignIn = modelUser; },
			error: () => { },
			complete: () => { }
		};

		const observerPartialModelStrategicAndPlanningSignIn: PartialObserver<StrategicAndPlanningModel> =
		{
			next: (modelStrategicAndPlanningModel: StrategicAndPlanningModel) => { this._modelStrategicAndPlanningSignIn = modelStrategicAndPlanningModel; },
			error: () => { },
			complete: () => { }
		};

		this._serviceSession._modelUserSignIn.subscribe(observerPartialModelUserSignIn);
		this._serviceSession._modelStrategicAndPlanningSignIn.subscribe(observerPartialModelStrategicAndPlanningSignIn);
	}

	//#endregion


	//#region FUNCTION

	protected signOut(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin"]);
	}

	protected signOutDownload(stringURL: string): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin", stringURL]);
	}

	protected confirmationAction(stringText: string): boolean
	{
		const booleanResult: boolean = confirm(stringText);
		return booleanResult;
	}

	//#endregion


	//#region CHECK

	protected checkURL(stringURLPath: string): boolean
	{
		return this._router.url.indexOf(stringURLPath) > -1;
	}

	//#endregion


	//#region GETTER

	public getMonthSelected(): any
	{
		this._booleanReadOnly = false;
	}

	//#endregion


	//#region GET LIST ENUM

	public getListEnumByName(stringEnumName: string): Array<EnumGeneralModel>
	{
		let enumSelected: any;

		if(stringEnumName === StringConstant.STRING_ENUM_DOCUMENT_TYPE)
		{
			enumSelected = ENUM_DOCUMENT_TYPE;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_CATEGORY_TYPE)
		{
			enumSelected = ENUM_CATEGORY_TYPE;
		}
		if(enumSelected)
		{
			const arrayObjectEnumGeneral = Object.keys(enumSelected)
				.filter(
					stringKey => !Number.isNaN(Number(enumSelected[stringKey])))
				.flatMap(
					stringKey => (
						[
							{ ID: enumSelected[stringKey], Name: stringKey }
						]
					)
				);

			const arrayModelEnumGeneral: Array<EnumGeneralModel> = [];
			arrayObjectEnumGeneral.forEach(
				objectEnumGeneralLoop =>
				{
					const pipeConvertSeparateEnumSpace: ConvertSeparateEnumSpace = new ConvertSeparateEnumSpace();
					const modelEnumGeneral: EnumGeneralModel = new EnumGeneralModel();
					modelEnumGeneral.ID = objectEnumGeneralLoop.ID;
					modelEnumGeneral.Name = pipeConvertSeparateEnumSpace.transform(objectEnumGeneralLoop.Name);

					arrayModelEnumGeneral.push(modelEnumGeneral);
				}
			);

			return arrayModelEnumGeneral;
		}
		else
		{
			return [];
		}

	}

	//#endregion

	//#region GET Editor Config

	protected setEditorConfig(): CKEDITOR.config
	{
		const configEditor: CKEDITOR.config =
				{
					uiColor: "#ffffff",
					toolbarGroups:
					[
						{ name: "clipboard", groups: [ "clipboard", "undo" ] },
						{ name: "forms", groups: [ "forms" ] },
						{ name: "basicstyles", groups: [ "basicstyles", "cleanup" ] },
						{ name: "paragraph", groups: [ "list", "indent", "blocks", "align", "paragraph" ] },
						{ name: "styles", groups: [ "styles" ] },
						{ name: "colors", groups: [ "colors" ] },
					],
					removeButtons:
						"bidi,Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,SelectAll,Find,Replace,Scayt,Form,Checkbox,TextField,Radio,Textarea,Select,Button,ImageButton,HiddenField,RemoveFormat,CopyFormatting,Blockquote,CreateDiv,Language,Anchor,Flash,HorizontalRule,SpecialChar,Smiley,PageBreak,Iframe,Maximize,About,ShowBlocks,Image,Styles,Format,Font",
					resize_enabled: false,
					skin: "moono-lisa",
					removePlugins: "elementspath,save,magicline,exportpdf",
					extraPlugins: "divarea,smiley,justify,indentblock,colordialog",
					tabSpaces: 4,
					colorButton_foreStyle:
					{
						element: "font",
						attributes: { color: "#(color)" },
						overrides: [ {
							element: "font", attributes: { color: null }
						} ]
					},
					colorButton_backStyle:
					{
						element: "font",
						styles: { "background-color": "#(color)" },
						overrides: [ {
							element: "font", attributes: { "background-color": null }
						} ]
					},
					colorButton_enableAutomatic: true,
					colorButton_enableMore: false,
					height: 400,
					removeDialogTabs: "image:advanced;link:advanced",
					fontSize_defaultLabel: "12",
					format_tags: "p;h1;h2;h3;pre;div",
					forcePasteAsPlainText: true,
					autoUpdateElement: false,
					allowedContent: true,
				};

		return configEditor;
	}

	//#endregion
}

//#endregion
